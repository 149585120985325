const customConst = {
    editorConfig: {
        toolbar: [
            {name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']},
            {
                name: 'paragraph',
                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-']
            },
            {name: 'links', items: ['Link', 'Unlink']},
            {name: 'document', items: ['Source']},
            {
                name: 'insert',
                // items: ['Image', 'Table']
                items: ['Table']
            },
        ],
        // filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
      // filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
      // filebrowserUploadUrl: 'http://localhost:8000/api/v1/custom/uploads/',
      // filebrowserImageUploadUrl: '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
        fileTools_requestHeaders: {
            'Authorization': "",
        },

      removeButtons: 'PasteFromWord',
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph"
                }
            ]
        },
        type: 'classic'
    },
    touristFields: [
        {type: 'text', name: 'first_name', id: 'first_name', label: 'First Name', required: true},
        {type: 'text', name: 'last_name', id: 'last_name', label: 'Last Name', required: true},
        {type: 'email', name: 'email', id: 'email', label: 'Email', required: true},
        {type: 'text', name: 'phone', id: 'phone', label: 'Phone', required: false},
        {type: 'text', name: 'company', id: 'company', label: 'Company'},
    ]
};

export default customConst;
