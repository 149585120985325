import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';
import {
    GET_TOURISTS_SUCCESS,
    GET_TOURISTS_ERROR,
    GET_TOURIST_INFO_SUCCESS,
    GET_TOURISTS_TOURS_SUCCESS,
    GET_TOURIST_INFO_REQUEST,
    GET_TOURISTS_REQUEST,
    CREATE_TOURIST_REQUEST
} from "./actionTypes";

const getTouristsRequest = () => {
    return {type: GET_TOURISTS_REQUEST}
};

const getTouristsSuccess = tourists => {
    return {type: GET_TOURISTS_SUCCESS, tourists};
};

const getTouristsError = error => {
    return {type: GET_TOURISTS_ERROR, error};
};

export const getTourists = (page, order_by, filter_by) => {
    console.log(filter_by)
    return async (dispatch) => {
        dispatch(getTouristsRequest());
        try {
            let response;
            let url = `/api/v1/tourists/`;

            let params = {}

            if (order_by) {
                params['order_by'] = order_by
            }

            if (filter_by) {
                params['filter_by'] = filter_by
            }

            if (page){
                params['page'] = page
            }

            response = await axios.get(`/api/v1/tourists/`, {params: params});

            dispatch(getTouristsSuccess(response.data));
            const obj = {
                isAdmin: response.data.is_admin,
                id: response.data.user_id,
                email: response.data.user_email
            };
            localStorage.setItem('role', JSON.stringify(obj));
            dispatch(getTouristsRequest());
        } catch (e) {
            dispatch(getTouristsRequest());
            dispatch(getTouristsError(e));
        }
    }
};

const getTouristInfoRequest = () => {
    return {type: GET_TOURIST_INFO_REQUEST}
};

const getTouristInfoSuccess = data => {
    return {type: GET_TOURIST_INFO_SUCCESS, data};
};

export const getTouristInfo = id => {
    return dispatch => {
        dispatch(getTouristInfoRequest());
        return axios.get(`/api/v1/tourists/${id}/`).then(response => {
            dispatch(getTouristInfoRequest());
            dispatch(getTouristInfoSuccess(response.data));
        }, error => {
            dispatch(getTouristInfoRequest(error));
        })
    }
};

const getTouristsToursSuccess = (data) => {
    return {type: GET_TOURISTS_TOURS_SUCCESS, data}
};

export const getTouristsTours = id => {
    return dispatch => {
        dispatch(getTouristInfoRequest());
        return axios.get(`/api/v1/tourists/${id}/tours/`).then(response => {
            dispatch(getTouristInfoRequest());
            dispatch(getTouristsToursSuccess(response.data));
        }, error => {
            dispatch(getTouristInfoRequest());
            dispatch(getTouristsError(error));
        })
    }
};


const createTouristRequest = () => {
    return {type: CREATE_TOURIST_REQUEST}
};

export const createTourist = (tourist, history) => {
    return async dispatch => {
        dispatch(createTouristRequest());
        try {
            await axios.post('/api/v1/tourists/', tourist);
            dispatch(createTouristRequest());
            history.push(`/tourists`);
        } catch (e) {
            dispatch(createTouristRequest());
            dispatch(getTouristsError(e));
            NotificationManager.error("Error", "Can not create tourist")
        }
    }
};

export const updateTourist = (data, id, history) => {
    return async dispatch => {
        dispatch(createTouristRequest());
        try {
            await axios.put(`/api/v1/tourists/${id}/`, data);
            dispatch(createTouristRequest());
            history.push('/tourists');
        } catch (e) {
            dispatch(createTouristRequest());
            dispatch(getTouristsError(e));
            NotificationManager.error("Error", "Can not update tourist!")
        }
    }
};
