import {GET_DRIVERS_DASHBOARD_SUCCESS, GET_GUIDES_DASHBOARD_SUCCESS, GET_ACCOM_DASHBOARD_SUCCESS, GET_DRIVERS_GUIDES_DASHBOARD} from '../actions/actionTypes';

const initialState = {
    driversData: null,
    guidesData: null,
    accomData: null,
    data: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_DRIVERS_DASHBOARD_SUCCESS:
            return {...state, driversData: action.data};
        case GET_GUIDES_DASHBOARD_SUCCESS:
            return {...state, guidesData: action.data};
        case GET_ACCOM_DASHBOARD_SUCCESS:
            return {...state, accomData: action.data};
        case GET_DRIVERS_GUIDES_DASHBOARD:
            return {...state, data: action.data};
        default:
            return state
    }
};

export default reducer;
