import React, {Fragment} from 'react';
import {
    FormGroup,
    Input,
    Label,
    Row,
    Col,
    Button,
    Alert
} from 'reactstrap';
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";
import DatePickerComponent from "../../UI/Datepicker/Datepicker";
import {faPaperclip, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const editorConfig = customConst.editorConfig;

const myRef = React.createRef();

const AddDayForm = (props) => {
    const clearImage = () => {
        myRef.current.value = null;

        props.removeImageInDay()
    };

    return (
        props.tourMetas ? <Fragment>

            <div className="add_day_form_wrapper">
                <Row>
                    <Col>
                        <DatePickerComponent
                            dates={props.dates}
                            selected={props.day.date}
                            setDate={props.setDate}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='location_a'>Location A *</Label>
                            <Input
                                type='select'
                                name='location_a'
                                value={props.day.location_a}
                                onChange={props.dayChange}
                            >
                                <option>Choose location A</option>
                                {props.tourMetas.regions.map(region => {
                                    return (
                                        <optgroup key={region.id} label={region.title}>
                                            {props.tourMetas.locations.map(location => {
                                                if (location.region === region.id) {
                                                    return (
                                                        <option value={location.id}
                                                                key={location.id}>{location.title}</option>
                                                    )
                                                }
                                            })}
                                        </optgroup>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label>Location B *</Label>
                            <Input
                                type='select'
                                onChange={props.dayChange}
                                name='location_b'
                                value={props.day.location_b}
                            >
                                <option>Choose location B</option>
                                {props.tourMetas.regions.map(region => {
                                    return (
                                        <optgroup key={region.id} label={region.title}>
                                            {props.tourMetas.locations.map(location => {
                                                if (location.region === region.id) {
                                                    return (
                                                        <option value={location.id}
                                                                key={location.id}>{location.title}</option>
                                                    )
                                                }
                                            })}
                                        </optgroup>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Additional Locations</Label>

                            <select
                                name="additional_locations"
                                value={props.day.additional_locations}
                                multiple={true}
                                onChange={props.handleClickAdditionalLocations}
                                className={'form-control'}
                            >
                                {props.tourMetas.regions.map(region => {
                                    return (
                                        <optgroup key={region.id} label={region.title}>
                                            {props.tourMetas.locations.map(location => {
                                                if (location.region === region.id) {
                                                    return (
                                                        <option value={location.id}
                                                                key={location.id}>{location.title}</option>
                                                    )
                                                }
                                            })}
                                        </optgroup>
                                    )
                                })}
                            </select>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label for='add_day_description'>Description</Label>
                        <CKEditor
                            id='add_day_description'
                            data={props.day.description === null ? '' : props.day.description}
                            config={editorConfig}
                            onChange={props.addDayCkEditorDescription}
                            name='description'
                            type={editorConfig.type}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label for='add_day_remarks'>Remarks</Label>
                        <CKEditor
                            id='add_day_remarks'
                            data={props.day.remarks === null ? '' : props.day.remarks}
                            config={editorConfig}
                            onChange={props.addDayCkEditorRemarks}
                            name='remarks'
                            type={editorConfig.type}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Image</Label>
                            <div className="tour_attachment_wrapper">
                                {props.day.image && (props.day.image !== '' && props.day.image.length > 0) ?
                                    <a className="attach_link" href={props.day.image} target='_blank'><FontAwesomeIcon className="icon" icon={faPaperclip} /> View</a>
                                    : null
                                }
                                <Input type='file'
                                       disabled={props.disabled}
                                       name='image'
                                       onChange={props.dayFileChange}
                                       innerRef={myRef}
                                />
                                {props.day.image && (props.day.image !== '' && props.day.image.length > 0) ?
                                    <span className='remove-file' onClick={clearImage}><FontAwesomeIcon
                                        icon={faTimesCircle}/></span>
                                    : null
                                }
                            </div>

                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="services_wrapper">
                            <Row>
                                <Col>
                                    <Label>Services</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {props.tourMetas.services.map(service => {
                                        const servicesInDay = props.day.services;
                                        return (
                                            <div key={service.id} className="form-check form-check-inline">
                                                <Input type='checkbox'
                                                       name={service.title}
                                                       id={`day_${props.count}_${service.id}`}
                                                       value={service.id}
                                                       onClick={props.handleClick}
                                                       defaultChecked={servicesInDay.includes(parseInt(service.id))}
                                                />
                                                <Label for={`day_${props.count}_${service.id}`}>{service.title}</Label>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button onClick={props.removeDay} color='danger'>Remove day</Button>
                    </Col>
                </Row>
                <div className="line_helper"></div>
            </div>


        </Fragment> : null
    );
};

export default AddDayForm;
