import axios from '../../axios-api';
import {GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_ERROR,
    GET_TEMPLATE_INFO_SUCCESS,
    GET_TEMPLATE_INFO_ERROR,
    TOGGLE_MODAL,
    DISABLE_BUTTON_SAVE_TEMPLATE
} from './actionTypes';
import {NotificationManager} from 'react-notifications';
import {errorHandler} from "./tourActions";

export const getTemplatesSuccess = data => ({type: GET_TEMPLATES_SUCCESS, data});
export const getTemplatesError = error => ({type: GET_TEMPLATES_ERROR, error});

export const getTemplates = (category, duration) => {
    return dispatch => {
        return axios.get(`/api/v1/templates/?category=${category}&duration=${duration}`).then(response => {
            dispatch(getTemplatesSuccess(response.data));
        }, error => {
            dispatch(getTemplatesError(error));
            NotificationManager.error('Error', 'Get templates error')
        });
    }
};

const getTemplateInfoSuccess = data => ({type: GET_TEMPLATE_INFO_SUCCESS, data});
const getTemplateInfoError = error => ({type: GET_TEMPLATE_INFO_ERROR, error});

export const getTemplateInfo = id => {
    return dispatch => {
        return axios.get(`/api/v1/templates/${id}/`).then(response => {
            response.data.days = response.data.days.map(day => ({
                ...day,
                date: new Date(day.date),
            }));
            dispatch(getTemplateInfoSuccess(response.data))
        }, error => {
            dispatch(getTemplateInfoError(error));
            NotificationManager.error('Error', 'Can not get template')
        })
    }
};


export const saveAsTemplate = data => {
    return async dispatch => {
        dispatch({type: DISABLE_BUTTON_SAVE_TEMPLATE});
        if(typeof data.image === 'string' && data.image.length > 0) {
            try {
                await axios.post(`/api/v1/create_template/${data.id}/`, {image: data.image})
                dispatch({type: TOGGLE_MODAL});
                dispatch({type: DISABLE_BUTTON_SAVE_TEMPLATE});
            } catch (e) {
                dispatch(errorHandler(e))
            }
        } else {
            try {
                await axios.post(`/api/v1/create_template/${data.id}/`);
                dispatch({type: TOGGLE_MODAL});
                dispatch({type: DISABLE_BUTTON_SAVE_TEMPLATE});
            } catch (e) {
                dispatch(errorHandler(e))
            }
        }
    }
};

export const closeModal = () => ({type: TOGGLE_MODAL});
