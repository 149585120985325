import preloader from './45.svg';
import React, {Fragment} from 'react';

const Preloader = () => {
    return (
        <Fragment>
            <div className="text-center">
                <img src={preloader} alt="preloader"/>
            </div>
        </Fragment>

    );
};

export default Preloader;
