import {GET_DRIVERS_DASHBOARD_SUCCESS, GET_GUIDES_DASHBOARD_SUCCESS, GET_ACCOM_DASHBOARD_SUCCESS, GET_DRIVERS_GUIDES_DASHBOARD} from './actionTypes';
import axios from '../../axios-api';
import {errorHandler} from "./tourActions";

export const getDriversDashboardSuccess = (data) => ({type: GET_DRIVERS_DASHBOARD_SUCCESS, data});

export const getDriversDashboard = (dates) => {
    return async dispatch => {
        if(dates) {
            try {
                const response = await axios.get(`/api/v1/dashboard/drivers/?start_date=${dates.start_date}&end_date=${dates.end_date}`);
                dispatch(getDriversDashboardSuccess(response.data))
            } catch (e) {
                dispatch(errorHandler(e))
            }
        } else {
            try {
                const response = await axios.get(`/api/v1/dashboard/drivers/`);
                dispatch(getDriversDashboardSuccess(response.data))
            } catch (e) {
                dispatch(errorHandler(e))
            }
        }
    }
};

export const getGuidesDashboardSuccess = data => ({type: GET_GUIDES_DASHBOARD_SUCCESS, data});

export const getGuidesDashboard = dates => {
    return async dispatch => {
        if(dates) {
            try {
                const response = await axios.get(`/api/v1/dashboard/guides/?start_date=${dates.start_date}&end_date=${dates.end_date}`);
                dispatch(getGuidesDashboardSuccess(response.data));
            } catch (e) {
                dispatch(errorHandler(e))
            }
        } else {
            const response = await axios.get(`/api/v1/dashboard/guides/`);
            dispatch(getGuidesDashboardSuccess(response.data));
        }
    }
};

export const getAccomDashboardSuccess = data => ({type: GET_ACCOM_DASHBOARD_SUCCESS, data});

export const getAccomDashboard = dates => {
    return async dispatch => {
        if(dates) {
            try {
                const response = await axios.get(`/api/v1/dashboard/accommodations/?start_date=${dates.start_date}&end_date=${dates.end_date}`);
                dispatch(getAccomDashboardSuccess(response.data));
            } catch (e) {
                dispatch(errorHandler(e))
            }
        } else {
            try {
                const response = await axios.get(`/api/v1/dashboard/accommodations/`);
                dispatch(getAccomDashboardSuccess(response.data));
            } catch (e) {
                dispatch(errorHandler(e))
            }
        }
    }
};

export const getGuidesAndDriversSuccess = data => ({type: GET_DRIVERS_GUIDES_DASHBOARD, data});

export const getGuidesAndDrivers = (dates) => {
    return async dispatch => {
        if(dates) {
            try {
                const response = await axios.get(`/api/v1/dashboard/guides_and_drivers/?start_date=${dates.start_date}&end_date=${dates.end_date}`);
                dispatch(getGuidesAndDriversSuccess(response.data));
            } catch (e) {
                dispatch(errorHandler(e))
            }
        } else {
            try {
                const response = await axios.get(`/api/v1/dashboard/guides_and_drivers/`);
                dispatch(getGuidesAndDriversSuccess(response.data));
            } catch (e) {
                dispatch(errorHandler(e))
            }
        }
    }
};
