import React, {Fragment, useState} from 'react';
import logo from '../../../img/logo_white.png';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    NavbarText,
    Dropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';


const Header = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Fragment>
            <div id="menu">
                <div className="container">
                    <Navbar expand="md">
                        <NavbarBrand href="/" className="logo"><img src={logo} alt="logo"/></NavbarBrand>
                        <Collapse navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink href="/tourists">Tourists</NavLink>
                                </NavItem>
                                <NavItem className="dashboard_menu_li">
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle caret>
                                            Dashboards
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                <NavLink href='/'>Plans dashboard</NavLink>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem>
                                                <NavLink href='/guides_drivers_dashboard'>Guides and Drivers</NavLink>
                                            </DropdownItem>
                                            <DropdownItem divider/>
                                            <DropdownItem>
                                                <NavLink href='/accommodations_dashboard' >Accommodations</NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavItem>
                            </Nav>
                            {props.user ? <NavbarText style={{marginRight: '20px'}}>{props.user.user}</NavbarText> : null}
                            {props.user ? <Button onClick={props.logout}>Logout</Button> : null}
                        </Collapse>
                    </Navbar>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
