import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import moment from 'moment';
import {
    Row,
    Col,
    Button,
    Alert
} from 'reactstrap';
import {getTourMetas} from "../../store/actions/tourActions";
import {
    createDriverOrder,
    getPlanDetail,
    saveTravelPlan,
    updateDriverOrder,
    createExtraOrder,
    createGuideOrder,
    updateExtraOrder,
    updateGuideOrder,
    createHomeStateOrder,
    updateHomeStateOrder,
    createGuestHouseOrder,
    updateGuestHouseOrder,
    createHotelOrder,
    updateHotelOrder,
    createYurtOrder,
    updateYurtOrder,
    cancelTravelPlan, getPlanReport
} from "../../store/actions/planActions";
import {NavLink, Link} from 'react-router-dom';

import DayComponent from "../../components/TravelPlanComponents/DayComponent/DayComponent";
import TravelPlanForm from "../../components/TravelPlanComponents/TravelPlanForm/TravelPlanForm";
import DriverForm from "../../components/TravelPlanComponents/ProvidersModalForms/DriverForm";
import ExtraForm from "../../components/TravelPlanComponents/ProvidersModalForms/ExtraForm";
import GuideForm from "../../components/TravelPlanComponents/ProvidersModalForms/GuideForm";
import HomeStateForm from "../../components/TravelPlanComponents/ProvidersModalForms/HomeStateForm";
import GuestHouseForm from "../../components/TravelPlanComponents/ProvidersModalForms/GuestHouseForm";
import HotelForm from "../../components/TravelPlanComponents/ProvidersModalForms/HotelForm";
import YurtCampForm from "../../components/TravelPlanComponents/ProvidersModalForms/YurtCampForm";

class EditTravelPlan extends Component {

    state = {
        orderModal: '',
        order: {
            plan: '',
            start_date: '',
            end_date: "",
            first_day: '',
            provider: '',
            mail: '',
            sms: '',
            note: '',
            status: ''
        },
        orderId: '',
        modal: false,
        providers: [],
        days: [],
        plan: {
            id: '',
            arrival_date: '',
            departure_date: '',
            cash: '',
            cashless: '',
            note: '',
            tour: '',
            estimate: '',
            country: '',
        },
        chosenDay: '',
        chosenDayDate: '',
        max_day_date: '',
        owner: ''
    };
    planId = '';


    async componentDidMount() {
        this.planId = this.props.match.params.id;
        await this.props.getPlanDetail(this.planId);
        let arrival_date;
        let departure_date;
        let plan = this.props.planDetails.plan;
        if (plan.arrival_date !== null || plan.departure_date !== null) {
            arrival_date = new Date(this.props.planDetails.plan.arrival_date);
            departure_date = new Date(this.props.planDetails.plan.departure_date);
        }
        plan.arrival_date = arrival_date;
        plan.departure_date = departure_date;
        this.setState({
            days: this.props.planDetails.days,
            providers: this.props.planDetails.days.providers,
            plan: plan,
            order: {
                ...this.state.order,
                end_date: this.props.planDetails.last_day_date,
                plan: this.planId
            }
        });

    }

    // PLAN HANDLERS

    setArrivalDate = date => {
        this.setState({plan: {...this.state.plan, arrival_date: date}})
    };

    setDepartureDate = date => {
        this.setState({plan: {...this.state.plan, departure_date: date}})
    };

    ckEditorChangeTravelPlan = (e) => {
        const data = e.editor.getData();
        this.setState({plan: {...this.state.plan, note: data}})
    };

    planChangeHandler = event => {
        this.setState({plan: {...this.state.plan, [event.target.name]: event.target.value}})
    };

    setCountry = event => {
        this.setState({plan: {...this.state.plan, country: parseInt(event.target.value)}})
        console.log(this.state.plan)
    }

    toggle = (day) => {
        if (day) {
            const max_date = moment(day.max_date).format('YYYY-MM-DD HH:mm');
            let order = {...this.state.order}
            order.mail = '';
            this.setState({
                modal: !this.state.modal,
                providers: day.providers,
                chosenDay: day.id,
                chosenDayDate: day.date,
                max_day_date: max_date,
                order,
            })
        } else {
            this.setState({
                modal: !this.state.modal
            })
        }
    };

    savePlan = (e) => {
        e.preventDefault();
        const data = {...this.state.plan};
        delete data.id;
        delete data.is_canceled;
        delete data.owner;
        delete data.tour_title;
        delete data.tourist;
        delete data.tourist_fullname;

        this.props.saveTravelPlan(data, this.props.match.params.id)
    };


    inputChangeHandler = e => {
        this.setState({
            order: {...this.state.order, [e.target.name]: e.target.value}
        })
    };

    ckEditorChange = (e) => {
        const data = e.editor.getData();
        this.setState({order: {...this.state.order, mail: data}})
    };


    openEditModal = (type, order, day) => {
        this.setState({
            orderModal: type,
            orderId: order.id,
            order: {
                ...this.state.order,
                mail: order.mail,
                note: order.note,
                sms: order.sms,
                plan: order.plan,
                end_date: order.end_date,
                start_date: order.start_date,
                last_sms: order.last_sms,
                last_email: order.last_email,
                last_sms_text: order.last_sms_text,
                last_email_mail: order.last_email_mail,
                provider: order.provider.id,
                status: order.status,
                first_day: day,
                is_blocked: order.is_blocked
            }
        });
    };

    setStartDateHandler = (date) => {
        this.setState({
            order: {...this.state.order, start_date: date}
        })
    };

    setEndDateHandler = date => {
        this.setState({
            order: {...this.state.order, end_date: date}
        })
    };


    createOrderHandler = (handler) => event => {
        const guideData = {
            ...this.state.order,
            start_date: moment(this.state.order.start_date).format('YYYY-MM-DD HH:mm'),
            end_date: moment(this.state.order.end_date).format('YYYY-MM-DD HH:mm'),
        };
        if (guideData.is_blocked) {
            delete guideData.is_blocked
        }
        if (event.target.name === 'save_send') {
            guideData.send = 1;
        }
        delete guideData.first_day;
        handler(guideData);
        this.closeModalHandler(false);
    };

    updateOrderHandler = handler => event => {
        const orderData = {
            ...this.state.order,
            start_date: moment(this.state.order.start_date).format('YYYY-MM-DD HH:mm'),
            end_date: moment(this.state.order.end_date).format('YYYY-MM-DD HH:mm')
        };
        if (event.target.name === 'update_send') {
            orderData.send = 1;
        }
        delete orderData.first_day;
        handler(orderData, this.state.orderId);
        this.closeModalHandler(false);
    };

    //// MODAL HANDLER
    modalHandler = (provider, tab) => {
        if (!provider) return;
        if (tab === 'home_state' || tab === 'yurt_camp' || tab === 'hotel' || tab === 'guest_house') {
            return this.setState({
                orderModal: tab,
                order: {
                    ...this.state.order,
                    provider: provider.id,
                    first_day: this.state.chosenDay,
                    start_date: this.state.chosenDayDate,
                    end_date: this.state.max_day_date,
                    status: 'none',
                    is_blocked: false
                }
            })
        } else {
            this.setState({
                orderModal: tab,
                order: {
                    ...this.state.order,
                    provider: provider.id,
                    first_day: this.state.chosenDay,
                    start_date: this.state.chosenDayDate,
                    end_date: this.props.planDetails.last_day_date,
                    status: 'none',
                    is_blocked: false
                }
            })
        }
    };

    closeModalHandler = (status = true) => {
        this.setState({
            modal: this.state.modal ? status : false,
            orderModal: '',
            orderId: '',
            order: {
                ...this.state.order,
                note: '',
                sms: '',
                mail: '',
                status: '',
                last_sms: '',
                last_email: '',
                last_sms_text: '',
                last_email_mail: '',
                // start_date: new Date(),
                // end_date: new Date()
            }
        });
    };

    cancelPlanHandler = () => {
        const answer = window.confirm('Are you sure?');
        if (answer) {
            this.props.cancelTravelPlan(this.planId).then(() => {
                window.location.reload();
            })
        }
    };

    getPlanReportHandler = () => {
        this.props.getPlanReport(this.planId).then(() => {
            // window.location.reload();
        })
    };

    renderButton = () => {
        const user = this.props.user;
        const plan = this.state.plan;
        if(user.is_admin === false) {
            if(user.user !== plan.owner) {
                return null
            } else {
                return (
                    <Fragment>
                        {this.props.planDetails && this.props.planDetails.plan.is_canceled ? null :
                            <Button color='danger' onClick={this.cancelPlanHandler}>
                                Cancel Plan
                            </Button>
                        }
                    </Fragment>
                )
            }
        } else {
            return (
                <Fragment>
                    {this.props.planDetails && this.props.planDetails.plan.is_canceled ? null :
                        <Button color='danger' onClick={this.cancelPlanHandler}>
                            Cancel Plan
                        </Button>
                    }
                </Fragment>
            )
        }
    };

    render() {
        if(!this.props.user) return <Redirect to='/login'/>;
        const user = this.props.user;

        let isVisible = true;

        if(user.is_admin === false) {
            if(user.user !== this.state.plan.owner) {
                isVisible = false
            } else {
                isVisible = true
            }
        }

        return (
            <Fragment>
                <Row>
                    <Col className="col-8 offset-sm-2">
                        <h1 className="page_title">Travel plan for tour #{this.state.plan.tour}</h1>
                        <div className="description">
                            {this.state.plan.tour && this.state.plan.tour_title ?
                                ['Tour ', <Link target="_blank" key="desc_2"
                                                to={`/tour_edit/${this.state.plan.tour}`}>"{this.state.plan.tour_title}" </Link>] : ''
                            }
                            {this.state.plan.tourist && this.state.plan.tourist_fullname ?
                                ['for ', <Link target="_blank" key="desc_4"
                                               to={`/editTourist/${this.state.plan.tourist}`}>{this.state.plan.tourist_fullname}</Link>] : ''
                            }
                        </div>

                        {this.props.planDetails && this.props.planDetails.plan.is_canceled ?
                            <div>
                                <br/>
                                <Alert color="danger">
                                    This travel plan is canceled. All providers will be notification by sms or email.
                                </Alert>
                            </div>
                        : null}

                        <div className="line_helper"></div>

                        {!this.state.plan.owner ? null :
                            <Fragment>
                                {this.props.planDetails && this.props.user ?
                                <TravelPlanForm
                                    savePlan={(e) => this.savePlan(e)}
                                    setArrivalDate={this.setArrivalDate}
                                    setDepartureDate={this.setDepartureDate}
                                    setCountry={this.setCountry}
                                    planChangeHandler={this.planChangeHandler}
                                    ckEditorChangeTravelPlan={this.ckEditorChangeTravelPlan}
                                    plan={this.state.plan}
                                    user={this.props.user}
                                /> : null}
                            </Fragment>
                        }

                        <Row id="travel_plan_form_wrapper">
                            <Col>
                                {this.props.planDetails &&
                                <NavLink to={`/plan_history/${this.planId}`}>
                                    <Button color='success'>Show history</Button>
                                </NavLink>
                                }
                            </Col>
                            <Col className="text-center">
                                {this.state.plan.owner ?
                                    <Fragment>
                                        {this.renderButton()}
                                    </Fragment>
                                    : null}
                            </Col>
                            {isVisible ?
                                <Col>
                                <div className="text-right">
                                    <Button color='primary' onClick={this.savePlan}>Save</Button>
                                </div>
                            </Col> : null}

                            <Col>
                                <Button color='success' onClick={this.getPlanReportHandler}>Print Out</Button>
                            </Col>
                        </Row>

                        {this.state.plan.owner ?
                            <Fragment>
                                {this.props.planDetails.plan.is_canceled ? null :
                                    <Fragment>
                                        {this.props.planDetails &&
                                        <DayComponent
                                            closeModalHandler={this.closeModalHandler}
                                            openEditModal={this.openEditModal}
                                            modalHandler={this.modalHandler}
                                            modal={this.state.modal}
                                            toggle={this.toggle}
                                            providers={this.state.providers}
                                            orders={this.props.planDetails.orders}
                                            days={this.props.planDetails.days}
                                            plan={this.state.plan}
                                            user={this.props.user}
                                        />}
                                    </Fragment>
                                }
                            </Fragment> : null}
                    </Col>
                </Row>

                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <DriverForm
                            plan={this.state.plan}
                            user={this.props.user}
                            closeModalHandler={this.closeModalHandler}
                            planDetails={this.props.planDetails}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateDriverOrder)}
                            driverOrderId={this.state.orderId}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            createOrderHandler={this.createOrderHandler(this.props.createDriverOrder)}
                            setStartDateHandler={this.setStartDateHandler}
                            setEndDateHandler={this.setEndDateHandler}
                            driver={this.state.order}
                            isOpen={this.state.orderModal === 'driver'}
                            driverModalHandler={this.modalHandler}
                        />
                        }
                    </Fragment> : null
                }

                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <ExtraForm
                            user={this.props.user}
                            plan={this.state.plan}
                            closeModalHandler={this.closeModalHandler}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateExtraOrder)}
                            createOrderHandler={this.createOrderHandler(this.props.createExtraOrder)}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            ckEditorChange={this.ckEditorChange}
                            inputChangeHandler={this.inputChangeHandler}
                            extraOrderId={this.state.orderId}
                            extraOrder={this.state.order}
                            isOpen={this.state.orderModal === 'extra'}
                            planDetails={this.props.planDetails}
                            modalHandler={this.modalHandler}
                        />
                        }
                    </Fragment> : null}


                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <GuideForm
                            user={this.props.user}
                            plan={this.state.plan}
                            isOpen={this.state.orderModal === 'guide'}
                            guideOrder={this.state.order}
                            planDetails={this.props.planDetails}
                            guideOrderId={this.state.orderId}
                            modalHandler={this.modalHandler}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            createOrderHandler={this.createOrderHandler(this.props.createGuideOrder)}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateGuideOrder)}
                            closeModalHandler={this.closeModalHandler}
                        />
                        }
                    </Fragment> : null}


                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <HomeStateForm
                            user={this.props.user}
                            plan={this.state.plan}
                            isOpen={this.state.orderModal === 'home_state'}
                            homeStateOrder={this.state.order}
                            planDetails={this.props.planDetails}
                            homeStateOrderId={this.state.orderId}
                            max_date={this.state.max_day_date}
                            closeModalHandler={this.closeModalHandler}
                            modalHandler={this.modalHandler}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            createOrderHandler={this.createOrderHandler(this.props.createHomeStateOrder)}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateHomeStateOrder)}
                        />}
                    </Fragment> : null}

                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <GuestHouseForm
                            user={this.props.user}
                            plan={this.state.plan}
                            isOpen={this.state.orderModal === 'guest_house'}
                            guestHouseOrder={this.state.order}
                            planDetails={this.props.planDetails}
                            guestHouseOrderId={this.state.orderId}
                            closeModalHandler={this.closeModalHandler}
                            modalHandler={this.modalHandler}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            createOrderHandler={this.createOrderHandler(this.props.createGuestHouseOrder)}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateGuestHouseOrder)}/>
                        }
                    </Fragment> : null}

                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <HotelForm
                            user={this.props.user}
                            plan={this.state.plan}
                            isOpen={this.state.orderModal === 'hotel'}
                            hotelOrder={this.state.order}
                            planDetails={this.props.planDetails}
                            hotelOrderId={this.state.orderId}
                            closeModalHandler={this.closeModalHandler}
                            modalHandler={this.modalHandler}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            createOrderHandler={this.createOrderHandler(this.props.createHotelOrder)}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateHotelOrder)}/>
                        }
                    </Fragment> : null}

                {this.state.plan.owner ?
                    <Fragment>
                        {this.props.planDetails &&
                        <YurtCampForm
                            user={this.props.user}
                            plan={this.state.plan}
                            isOpen={this.state.orderModal === 'yurt_camp'}
                            yurtOrder={this.state.order}
                            planDetails={this.props.planDetails}
                            yurtOrderId={this.state.orderId}
                            closeModalHandler={this.closeModalHandler}
                            modalHandler={this.modalHandler}
                            inputChangeHandler={this.inputChangeHandler}
                            ckEditorChange={this.ckEditorChange}
                            setEndDateHandler={this.setEndDateHandler}
                            setStartDateHandler={this.setStartDateHandler}
                            createOrderHandler={this.createOrderHandler(this.props.createYurtOrder)}
                            updateOrderHandler={this.updateOrderHandler(this.props.updateYurtOrder)}
                        />
                        }
                    </Fragment> : null}

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    planDetails: state.plan.planDetail,
    tourMetas: state.tours.tourMetas,
    plan: state.plan.plan,
    user: state.usr.user
});

const mapDispatchToProps = dispatch => ({
    getTourMetas: () => dispatch(getTourMetas()),
    getPlanDetail: id => dispatch(getPlanDetail(id)),
    createDriverOrder: (data) => dispatch(createDriverOrder(data)),
    createExtraOrder: data => dispatch(createExtraOrder(data)),
    createGuideOrder: data => dispatch(createGuideOrder(data)),
    saveTravelPlan: (data, id) => dispatch(saveTravelPlan(data, id)),
    updateDriverOrder: (data, id) => dispatch(updateDriverOrder(data, id)),
    updateExtraOrder: (data, id) => dispatch(updateExtraOrder(data, id)),
    updateGuideOrder: (data, id) => dispatch(updateGuideOrder(data, id)),
    createHomeStateOrder: data => dispatch(createHomeStateOrder(data)),
    updateHomeStateOrder: (data, id) => dispatch(updateHomeStateOrder(data, id)),
    createGuestHouseOrder: data => dispatch(createGuestHouseOrder(data)),
    updateGuestHouseOrder: (data, id) => dispatch(updateGuestHouseOrder(data, id)),
    createHotelOrder: data => dispatch(createHotelOrder(data)),
    updateHotelOrder: (data, id) => dispatch(updateHotelOrder(data, id)),
    createYurtOrder: data => dispatch(createYurtOrder(data)),
    updateYurtOrder: (data, id) => dispatch(updateYurtOrder(data, id)),
    cancelTravelPlan: id => dispatch(cancelTravelPlan(id)),
    getPlanReport: id => dispatch(getPlanReport(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTravelPlan);
