import React, {useState, Fragment} from 'react';
import {Tooltip} from 'reactstrap';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TooltipComponent = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const custom_html = props.description;

    return (
        <Fragment>
                <span id={props.id}><FontAwesomeIcon icon={faQuestionCircle}
                                                     style={{height: '25px'}}/></span>
            <Tooltip placement="right" isOpen={tooltipOpen} target={props.id} datahtml="true" toggle={toggle}
                     popperClassName={props.popperClassName}
                     dangerouslySetInnerHTML={{__html: custom_html}}>
            </Tooltip>
        </Fragment>
    );
};

export default TooltipComponent;
