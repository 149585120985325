import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <p>Powered by <a href="https://feoktistov.dev">feoktistov.dev</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
