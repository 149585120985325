import {
    GET_TOUR_METAS_SUCCESS,
    GET_TOUR_ERROR,
    GET_TOURISTS_TOURS_REQUEST,
    GET_TOUR_METAS_REQUEST,
    GENERATE_PDF_ERROR,
    GET_TOUR_DETAILS_SUCCESS,
    GET_TOURS_INVOICES_SUCCESS, UPDATE_TOUR_ERROR
} from "../actions/actionTypes";

const initialState = {
    tourDetails: null,
    tourMetas: null,
    toursInvoices: null,
    error: null,
    isLoading: false,
    updateError: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_TOUR_METAS_SUCCESS:
            return {...state, tourMetas: action.data};
        case GET_TOUR_ERROR:
            return {...state, error: action.error};
        case GET_TOURISTS_TOURS_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case GET_TOUR_METAS_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case GENERATE_PDF_ERROR:
            return {...state, error: action.error};
        case GET_TOUR_DETAILS_SUCCESS:
            return {...state, tourDetails: action.data};
        case GET_TOURS_INVOICES_SUCCESS:
            return {...state, toursInvoices: action.data};
        case UPDATE_TOUR_ERROR:
            return {...state, updateError: action.error};
        default:
            return state;
    }
};

export default reducer;
