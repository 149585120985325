import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import React from "react";

export const renderOrders = (item, label, name, handler) => {
    const items = [];
    let count = 1;
    for (let order of item[name]) {
        let start_date_date = new Date(order.start_date).toDateString();
        let start_date_time = ("0" + new Date(order.start_date).getHours()).slice(-2) + ':' + ("0" + new Date(order.start_date).getMinutes()).slice(-2)
        let end_date_date = new Date(order.end_date).toDateString();
        let end_date_time = ("0" + new Date(order.end_date).getHours()).slice(-2) + ':' + ("0" + new Date(order.end_date).getMinutes()).slice(-2);

        if (label === 'driver') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Driver: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_driver_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'extra') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Extra: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_extra_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'guide') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Guide: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_guide_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'yurt_camp') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Yurt Camp: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_yurt_camp_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'hotel') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Hotel: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_hotel_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'home_state') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Home State: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_home_state_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        } else if (label === 'guest_house') {
            items.push(
                <div key={name + count}>
                    <h5 className={order.status + " order_button"}
                        onClick={() => handler(label, order, item.day)}>
                        Guest House: {order.provider.title}
                        <TooltipComponent
                            description={[start_date_date + ' ' + start_date_time + ' <br> ' + end_date_date + ' ' + end_date_time]}
                            id={`tooltip_guest_house_date_`+order.id}
                            popperClassName="tooltip_in_one_line"
                        />
                    </h5>
                </div>
            );
        }
        count++
    }
    return items;
};
