import axios from 'axios';
import store from './store/configureStore';

import {loadState} from './store/localStorage';
import {destroySession, refreshTokenSuccess} from "./store/actions/userActions";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL
});

instance.interceptors.request.use(req => {
    if (window.location.pathname === '/login') {
        return req
    } else {
        const token = store.getState().usr.user.access;
        if (token) {
            req.headers.Authorization = `Bearer ${token}`;
            return req;
        }
    }
}, error => {
    Promise.reject(error)
});

instance.interceptors.response.use(res => {
    return res
}, (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === '/api/v1/token/refresh/') {

        localStorage.removeItem('state');
        store.dispatch(destroySession());
        window.location.href = process.env.REACT_APP_BASEURL;
        return Promise.reject(error);
    }

    if (error.response.status === 401) {
        originalRequest._retry = true;
        return instance.post('/api/v1/token/refresh/', {"refresh": loadState().usr.user.refresh})
            .then(res => {
                if (res.status === 200) {
                    store.dispatch(refreshTokenSuccess(res.data.access));
                    return instance.request(originalRequest);
                }
            })
    }
    return Promise.reject(error)
});


export default instance;
