export const REQUEST = 'REQUEST';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_TOURISTS_REQUEST = 'GET_TOURISTS_REQUEST';
export const GET_TOURISTS_SUCCESS = 'GET_TOURISTS_SUCCESS';
export const GET_TOURISTS_ERROR = 'GET_TOURISTS_ERROR';

export const GET_TOURIST_INFO_REQUEST = 'GET_TOURIST_INFO_REQUEST';
export const GET_TOURIST_INFO_SUCCESS = 'GET_TOURIST_INFO_SUCCESS';

export const GET_TOURISTS_TOURS_REQUEST = 'GET_TOURISTS_TOURS_REQUEST';
export const GET_TOURISTS_TOURS_SUCCESS = 'GET_TOURISTS_TOURS_SUCCESS';

export const GET_TOUR_METAS_REQUEST = 'GET_TOUR_METAS_REQUEST';
export const GET_TOUR_METAS_SUCCESS = 'GET_TOUR_METAS_SUCCESS';
export const GET_TOUR_ERROR = 'GET_TOUR_ERROR';

export const GENERATE_PDF_ERROR = 'GENERATE_PDF_ERROR';

export const GET_TOUR_DETAILS_REQUEST = 'GET_TOUR_DETAILS_REQUEST';
export const GET_TOUR_DETAILS_SUCCESS = 'GET_TOUR_DETAILS_SUCCESS';
export const GET_TOUR_DETAILS_ERROR = 'GET_TOUR_DETAILS_ERROR';

export const GET_TOURS_INVOICES_SUCCESS = 'GET_TOURS_INVOICES_SUCCESS';
export const GET_TOURS_INVOICES_ERROR = 'GET_TOURS_INVOICES_ERROR';

export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';

export const GET_TEMPLATE_INFO_SUCCESS = 'GET_TEMPLATE_INFO_SUCCESS';
export const GET_TEMPLATE_INFO_ERROR = 'GET_TEMPLATE_INFO_ERROR';

export const CREATE_TOURIST_REQUEST = 'CREATE_TOURIST_REQUEST';

export const DESTROY_SESSION = 'DESTROY_SESSION';

export const UPDATE_TOUR_REQUEST = 'UPDATE_TOUR_REQUEST';
export const UPDATE_TOUR_ERROR = 'UPDATE_TOUR_ERROR';

export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const DISABLE_BUTTON_SAVE_TEMPLATE = 'DISABLE_BUTTON_SAVE_TEMPLATE';

export const CREATE_TRAVEL_PLAN_REQUEST = 'CREATE_TRAVEL_PLAN_REQUEST';
export const CREATE_TRAVEL_PLAN_ERROR = 'CREATE_TRAVEL_PLAN_ERROR';
export const CREATE_TRAVEL_PLAN_SUCCESS = 'CREATE_TRAVEL_PLAN_SUCCESS';

export const GET_PLAN_DETAIL_SUCCESS = 'GET_PLAN_DETAIL_SUCCESS';
export const GET_PLAN_DETAIL_ERROR = 'GET_PLAN_DETAIL_ERROR';
export const GET_PLAN_DETAIL_REQUEST = 'GET_PLAN_DETAIL_REQUEST';

export const GET_PLANS_LIST_SUCCESS = 'GET_PLANS_LIST_SUCCESS';

export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';

export const GET_PLANS_HISTORY_SUCCESS = 'GET_PLANS_HISTORY_SUCCESS';

export const GET_DRIVERS_DASHBOARD_SUCCESS = 'GET_DRIVERS_DASHBOARD_SUCCESS';
export const GET_GUIDES_DASHBOARD_SUCCESS = 'GET_GUIDES_DASHBOARD_SUCCESS';
export const GET_ACCOM_DASHBOARD_SUCCESS = 'GET_ACCOM_DASHBOARD_SUCCESS';

export const GET_DRIVERS_GUIDES_DASHBOARD = 'GET_DRIVERS_GUIDES_DASHBOARD';
