import React, {Fragment} from 'react';
import {Label, FormGroup, Input, Row, Col, Button, Alert} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faPaperclip} from "@fortawesome/free-solid-svg-icons";

const myRef = React.createRef();

const EditFileForm = props => {

    const clearFile = () => {
        myRef.current.value = null;

        props.removeFileInFiles();
    };

    return (
        <Fragment>
            <Row>

                {props.filesUrls[props.index] && props.filesUrls[props.index].file !== '' ?
                    <Col>
                        <FormGroup>
                            <Label>File</Label>
                            <div className="tour_attachment_wrapper">

                                {props.filesUrls[props.index] && props.filesUrls[props.index].file !== '' ?
                                    <a className="attach_link" href={props.filesUrls[props.index].file} target='_blank'><FontAwesomeIcon className="icon" icon={faPaperclip} /> View</a>
                                    :
                                    null
                                }

                                    <Input type='file'
                                           disabled={props.disabled}
                                           onChange={props.fileChange}
                                           name='file'
                                           innerRef={myRef}
                                    />
                                    {!props.disabled ?
                                        <span onClick={props.deleteFile} className='remove-file'><FontAwesomeIcon icon={faTimesCircle} /></span>
                                    : null}
                                </div>

                            </FormGroup>
                    </Col>
                    :
                    <Col>
                        <FormGroup>
                            <Label>File</Label>
                            <div className="tour_attachment_wrapper">
                                <Input type='file'
                                       disabled={props.disabled}
                                       onChange={props.fileChange}
                                       name='file'
                                       innerRef={myRef}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                }
            </Row>
        </Fragment>
    );
};

export default EditFileForm;
