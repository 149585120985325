import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getPlansHistory} from "../../store/actions/plansHistoryActions";
import {
    Nav,
    NavItem,
    NavLink,
    Collapse,
    CardHeader,
    Card,
    ListGroup,
    ListGroupItem,
    Alert
} from 'reactstrap';
import classnames from 'classnames';
import update from "react-addons-update";
import OrderModal from "../../components/PlanHistoryComponents/OrderModal/OrderModal";

class PlansHistory extends Component {

    state = {
        activeTab: '',
        orders: [],
        order: null,
        isOpenModal: false
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getPlansHistory(id).then(() => {
            this.setState({
                activeTab: 'driver_orders',
                orders: this.props.planHistory['driver_orders']
            });
        });
    }

    toggle = tab => {
        let orders = this.props.planHistory[tab];
        for (let order of orders) {
            order.collapsed = false
        }
        this.setState({
            activeTab: tab,
            orders
        })
    };

    modalHandler = (order) => {
        if (!order) {
            order = null;
        }
        this.setState({
            order: order,
            isOpenModal: !this.state.isOpenModal
        })
    };

    setIsCollapseOpen = (index) => {
        this.setState({
            orders: update(this.state.orders, {[index]: {collapsed: {$set: !this.state.orders[index].collapsed}}})
        })
    };

    renderNavs = () => {
        let navs = [];
        const history = this.props.planHistory;
        for (let key in history) {
            let count = 1;
            if (history[key]) {
                if (key === 'driver_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Driver Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'home_state_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Home State Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'guest_house_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Guest House Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'extra_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Extra Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'guide_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Guide Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'yurt_camp_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Yurt Camp Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                } else if (key === 'hotel_orders') {
                    const nav = (
                        <NavItem key={count}>
                            <NavLink
                                className={classnames({active: this.state.activeTab === key})}
                                onClick={() => this.toggle(key)}
                            >
                                Hotel Orders
                            </NavLink>
                        </NavItem>
                    );
                    count++;
                    navs.push(nav);
                }
            }
        }
        return navs;
    };

    render() {
        let count = 1;
        return (
            this.props.planHistory &&
            <div className='col-md-10 offset-md-1'>
                <Nav tabs>
                    {this.renderNavs()}
                </Nav>
                {this.state.orders ? this.state.orders.map((order, index) => {
                    const [name] = Object.keys(order);
                    return (
                        <Card className='order_card' key={index}>
                            <CardHeader className='order_date'
                                        onClick={() => this.setIsCollapseOpen(index)}>{name}</CardHeader>
                            <Collapse isOpen={this.state.orders[index].collapsed}>
                                <ListGroup key={index}>
                                    {order[name].map((order, index) => {
                                        let updates_date = new Date(order.updated_at).toDateString();
                                        let updated_time = ("0" + new Date(order.updated_at).getHours()).slice(-2) + ':' + ("0" + new Date(order.updated_at).getMinutes()).slice(-2)
                                        return (

                                            <ListGroupItem key={index} className={`${order.status} order_date`}
                                                           onClick={() => this.modalHandler(order)}>{updates_date + ' ' + updated_time}
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>
                            </Collapse>
                        </Card>
                    );
                    count += 1
                }) : <Alert color='warning'>No history yet!</Alert>}


                {this.state.order &&
                <OrderModal
                    modalHandler={this.modalHandler}
                    isOpen={this.state.isOpenModal}
                    order={this.state.order}
                />}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    planHistory: state.planHistory.planHistory
});

const mapDispatchToProps = dispatch => ({
    getPlansHistory: id => dispatch(getPlansHistory(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansHistory);
