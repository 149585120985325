import React, {Fragment} from 'react';
import {Col, FormGroup, Label, Row, Button, Input, Form} from "reactstrap";
import {faMinusCircle, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";

const editorConfig = customConst.editorConfig;

const CreateTourPrice = props => {
    return (
        <Fragment>
            {props.data ? props.data.data.map((item, index) => {
                return (
                    <Row key={index}>
                        <Col>
                            <FormGroup>
                                <Label>Pax</Label>
                                <Input
                                    value={item.pax}
                                    onChange={props.changePaxHandler(index)}
                                    name='pax'
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Label>Price</Label>
                            <Input value={item.price}
                                   name={'price'}
                                   onChange={props.changePriceHandler(index)}
                                   required
                            />
                        </Col>
                        <Col>
                            <span className={'delete_price'} onClick={() => props.deletePrice(item)}>
                                <FontAwesomeIcon icon={faMinusCircle}/>
                            </span>
                        </Col>
                    </Row>
                )
            }) : null}

            <Row>
                <Col>
                    <Button onClick={props.addPriceAndPaxHandler} color='primary'>
                        Add Price
                    </Button>
                </Col>

                <Col>
                    <Row>
                        <Col>
                            <div className="form-radio form-radio-inline">
                                <Input
                                    id={'usd_radio'}
                                    type='radio'
                                    name='currency'
                                    onChange={() => props.changeCurrencyHandler('USD')}
                                    defaultChecked={props.data.currency === 'USD' ? props.data.currency : false}
                                />
                                <Label for={'usd_radio'}>USD</Label>
                            </div>
                        </Col>

                        <Col>
                            <div className='form-radio form-radio-inline'>
                                <Input
                                    id={'eur_radio'}
                                    type='radio'
                                    name='currency'
                                    onChange={() => props.changeCurrencyHandler('EUR')}
                                    defaultChecked={props.data.currency === 'EUR' ? props.data.currency : false}
                                />
                                <Label for={'eur_radio'}>EUR</Label>
                            </div>
                        </Col>

                        <Col>
                            <div className='form-radio form-radio-inline'>
                                <Input
                                    id={'kgs_radio'}
                                    type='radio'
                                    name='currency'
                                    onChange={() => props.changeCurrencyHandler('KGS')}
                                    defaultChecked={props.data.currency === 'KGS' ? props.data.currency : false}
                                />
                                <Label for={'kgs_radio'}>KGS</Label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="line_helper"></div>

            <Row>
                <Col>
                    <FormGroup>
                        <Label for='add_tour_included'>Included</Label>
                        <CKEditor
                            id='add_tour_included'
                            data={props.tour.included === null ? '' : props.tour.included}
                            config={editorConfig}
                            onChange={props.addTourIncludedCk}
                            name='included'
                            type={editorConfig.type}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default CreateTourPrice;
