import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {createTourist} from "../../store/actions/touristsActions";
import FormElement from "../../components/UI/FormElement/FormElement";
import {Button, Form, FormGroup, Label} from "reactstrap";

import customConst from '../../global.js';
import CKEditor from "ckeditor4-react";

const editorConfig = customConst.editorConfig;
const fields = customConst.touristFields;

class CreateTourist extends Component {

    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        note: '',
        company: ''
    };

    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    ckeditorChangeHandler = (e) => {
        const data = e.editor.getData();
        this.setState({note: data});
    };

    submit = e => {
        e.preventDefault();
        this.props.createTourist(this.state, this.props.history);
    };

    render() {
        return (

            <Fragment>
                <div className="col-sm-6">
                    <div>
                        <h1 className="page_title">Add new tourist</h1>
                    </div>
                    <Form onSubmit={this.submit}>
                        <FormElement
                            fields={fields}
                            change={this.changeHandler}
                            ckeditor_change={this.ckeditorChangeHandler}
                            clickHandler={this.submit}
                            state={this.state}
                            send={'Save'}
                        />

                        <Label for='tourist_note'>Note</Label>
                        <CKEditor
                            id='tourist_note'
                            type={editorConfig.type}
                            data={this.state.note}
                            config={editorConfig}
                            onChange={this.ckeditorChangeHandler}
                            name='note'
                        />

                        <div className="line_helper"></div>
                        <FormGroup>
                            <Button type='submit' color='primary'>Save</Button>
                        </FormGroup>
                    </Form>
                </div>
            </Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    createTourist: (tourist, history) => dispatch(createTourist(tourist, history))
});

export default connect(null, mapDispatchToProps)(CreateTourist);
