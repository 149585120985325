import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

import Login from "./containers/LoginPage/LoginPage";
import MainPage from "./containers/MainPage/MainPage";
import TouristInfo from "./containers/TouristInfo/TouristInfo";
import CreateTourist from "./containers/CreateTourist/CreateTourist";
import EditTourist from "./containers/EditTourist/EditTourist";
import CreateTour from "./containers/CrateTour/CreateTour";
import EditTour from "./containers/EditTour/EditTour";
import EditTravelPlan from "./containers/EditTravelPlan/EditTravelPlan";
import PlansList from "./containers/PlansList/PlansList";
import PlansHistory from './containers/PlansHistory/PlansHistory';
import Layout from "./components/Layout/Layout";
import AppRouter from "./components/AppRouter/AppRouter";
import ListLayout from "./components/ListLayout/ListLayout";
import DriversDashboard from "./containers/DriversDashboard/DriversDashboard";
import GuidesDashboard from './containers/GuidesDashboard/GuidesDashboard';
import AccommodationsDashboard from "./containers/AccommodationsDashboard/AccommodationsDashboard";
import GuidesAndDriversDashboard from './containers/GuidesAndDriversDashboard/GuidesAndDriversDashboard';


const Routes = () => {
    return (
        <Switch>
            <AppRouter path='/' exact component={PlansList} layout={ListLayout}/>
            <AppRouter path='/login' exact component={Login} layout={Layout}/>
            <AppRouter path='/tourists' component={MainPage} layout={Layout}/>
            <AppRouter path='/travel_plan/:id' component={EditTravelPlan} layout={Layout}/>
            <AppRouter path='/create-tourist' component={CreateTourist} layout={Layout}/>
            <AppRouter path='/create-tour/:id' component={CreateTour} layout={Layout}/>
            <AppRouter path='/tourist-info/:id' component={TouristInfo} layout={Layout}/>
            <AppRouter path='/editTourist/:id' component={EditTourist} layout={Layout}/>
            <AppRouter path='/tour_edit/:id' component={EditTour} layout={Layout}/>
            <AppRouter path='/plan_history/:id' component={PlansHistory} layout={Layout}/>
            {/*<AppRouter path='/drivers_dashboard' component={DriversDashboard} layout={ListLayout}/>*/}
            {/*<AppRouter path='/guides_dashboard' component={GuidesDashboard} layout={ListLayout}/>*/}
            <AppRouter path='/all_plans' component={PlansList} layout={ListLayout}/>
            <AppRouter path='/guides_drivers_dashboard' component={GuidesAndDriversDashboard} layout={ListLayout}/>
            <AppRouter path='/accommodations_dashboard' component={AccommodationsDashboard} layout={ListLayout}/>
        </Switch>
    )
};

export default withRouter(Routes);
