import {
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_ERROR,
    GET_TEMPLATE_INFO_SUCCESS,
    GET_TEMPLATE_INFO_ERROR,
    TOGGLE_MODAL, DISABLE_BUTTON_SAVE_TEMPLATE
} from "../actions/actionTypes";

const initialState = {
    templates: null,
    templateInfo: null,
    error: null,
    isOpen: false,
    disabledTempBtn: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_TEMPLATES_SUCCESS:
            return {...state, templates: action.data};
        case GET_TEMPLATES_ERROR:
            return {...state, error: action.error};
        case GET_TEMPLATE_INFO_SUCCESS:
            return {...state, templateInfo: action.data};
        case GET_TEMPLATE_INFO_ERROR:
            return {...state, error: action.error};
        case TOGGLE_MODAL:
            return {...state, isOpen: !state.isOpen};
        case DISABLE_BUTTON_SAVE_TEMPLATE:
            return {...state, disabledTempBtn: !state.disabledTempBtn};
        default:
            return state;
    }
};

export default reducer;
