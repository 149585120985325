import React, {Fragment} from 'react';
import {Col, FormGroup, Input, Label, Row, Button} from "reactstrap";

const ChooseTemplateForm = props => {
    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label><b>Template category</b></Label>
                        <Input type='select'
                               name='category'
                               value={props.category}
                               onChange={props.templateChangeHandler}
                        >
                            <option value=''>Choose category</option>
                            {props.tourMetas.categories.map(category => {
                                return (
                                    <option value={category.id} key={category.id}>{category.title}</option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label><b>Template duration</b></Label>
                        <Input type='select'
                               name='duration'
                               value={props.duration}
                               onChange={props.templateChangeHandler}
                        >
                            <option>Choose duration</option>
                            {props.tourMetas.durations.map(duration => {
                                return (
                                    <option value={duration.id} key={duration.id}>{duration.title}</option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Button disabled={!(props.duration && props.category)} color='primary' onClick={props.findTemplate}>Find templates</Button>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ChooseTemplateForm;
