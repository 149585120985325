import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import update from 'react-addons-update';
import {createTour, getTourMetas} from '../../store/actions/tourActions';
import AddFormTour from "../../components/CreateTourForms/AddTourForm/AddFormTour";
import {Button, Form, FormGroup, Col, Row, Input} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {getTouristInfo} from "../../store/actions/touristsActions";
import Preloader from "../../components/UI/Preloader/Preloader";
import AddDayForm from "../../components/CreateTourForms/AddTourForm/AddDayForm";
import CreateFileForm from '../../components/CreateTourForms/AddTourForm/CreateFileForm';
import InvoicesTabs from "../../components/UI/InvoicesTabs/InvoicesTabs";
import {getTemplateInfo, getTemplates} from "../../store/actions/templatesActions";
import ChooseTemplateForm from "../../components/CreateTourForms/ChooseTemplateForm/ChooseTemplateForm";
import CreateTourPrice from "../../components/CreateTourForms/CreateTourPrice/CreateTourPrice";
import moment from "moment";

class CreateTour extends Component {
    day = {
        date: '',
        location_a: '',
        location_b: '',
        description: '',
        remarks: '',
        services: [],
        additional_locations: [],
        image: '',
    };

    file = {
        file: ''
    };

    eurDetail = {
        invoice: 'eur',
        detail: '',
        deal: '',
        sum: ''
    };

    usdDetail = {
        invoice: 'usd',
        detail: '',
        deal: '',
        sum: ''
    };

    data = {
        1: ""
    };

    state = {
        category: '',
        duration: '',
        template: '',
        dates: [],
        tour: {
            description: '',
            title: '',
            tourist: '',
            duration: '',
            requirements: '',
            price_and_pax: {
                currency: "USD",
                data: []
            },
            category: '',
            included: '',
            excel: '',
            image: ''
        },
        days: [],
        files: [],
        usdDetails: [],
        eurDetails: [],
        eurInvoice: {
            type: 1,
        },
        usdInvoice: {
            type: 2,
        }
    };
    id = this.props.match.params.id;
    imageUrl = '';
    imageFile = '';

    componentDidMount() {
        this.props.getTourMetas();
        this.props.getTouristInfo(this.id);
        // this.addNewDayHandler();
        this.addFile();
        this.setState({tour: {...this.state.tour, tourist: this.id}})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.templateInfo !== this.props.templateInfo) {
            const templateInfo = this.props.templateInfo;
            const days = this.props.templateInfo.days;
            this.imageUrl = this.props.templateInfo.image;

            delete templateInfo.days;
            delete templateInfo.image;
            this.setState({
                days: days,
                tour: templateInfo
            }, () => {
                this.setState({
                    tour: {...this.state.tour, tourist: this.id}
                })
            })
        }
    }

    ///// INVOICES HANDLERS

    usdChangeHandler = event => {
        this.setState({
            usdInvoice: {...this.state.usdInvoice, [event.target.name]: event.target.value},
        })
    };
    eurChangeHandler = event => {
        this.setState({eurInvoice: {...this.state.eurInvoice, [event.target.name]: event.target.value}})
    };

    addUsdDetail = () => {
        this.setState({
            usdDetails: this.state.usdDetails.concat(this.usdDetail)
        })
    };
    addEurDetail = () => {
        this.setState({
            eurDetails: this.state.eurDetails.concat(this.eurDetail)
        })
    };

    deleteEurDetail = index => {
        const details = this.state.eurDetails;
        details.splice(index, 1);
        this.setState({
            eurDetails: details
        })
    };

    deleteUsdDetail = index => {
        const details = this.state.usdDetails;
        details.splice(index, 1);
        this.setState({
            usdDetails: details
        })
    };

    usdDetailChangeHandler = index => e => {
        this.setState({
            usdDetails: update(this.state.usdDetails, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };
    eurDetailChangeHandler = index => e => {
        this.setState({
            eurDetails: update(this.state.eurDetails, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };

    usdCkEditorDetail = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            usdDetails: update(this.state.usdDetails, {[index]: {detail: {$set: data}}})
        })
    };
    eurCkEditorDetail = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            eurDetails: update(this.state.eurDetails, {[index]: {detail: {$set: data}}})
        })
    };
    /////

    /// HANDLERS FOR FILES

    removeFile = index => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({files});
    };

    removeExcelInFiles = index => () => {
        this.setState({
            files: update(this.state.files, {[index]: {file: {$set: ''}}})
        });
    };

    addFile = () => {
        this.setState({
            files: this.state.files.concat(this.file)
        })
    };

    pdfFileChange = index => event => {
        this.setState({
            files: update(this.state.files, {[index]: {[event.target.name]: {$set: event.target.files[0]}}})
        })
    };

    /// HANDLERS FOR DAY

    removeImageInDay = index => () => {
        this.setState({
            days: update(this.state.days, {[index]: {image: {$set: ''}}}),
        })
    };

    addNewDayHandler = () => {
        if (this.state.days.length === 0) {
            const day = {...this.day};
            const days = [...this.state.days];
            day.date = new Date();
            days.push(day);
            return this.setState({
                days: days
            })
        }

        if (this.state.days.length === 1) {
            const day = {...this.day};
            const days = [...this.state.days];
            let dayObj = {...days[0]};
            day.date = dayObj.date;
            let tomorrow = moment(day.date).add(1, 'days');

            day.date = tomorrow.toDate();
            day.location_a = dayObj.location_b;
            days.push(day);
            this.setState({
                days: days
            })
        } else if (this.state.days.length > 1) {
            const day = {...this.day};
            const days = [...this.state.days];
            let dayObj = {...days[days.length - 1]};
            day.date = dayObj.date;
            let tomorrow = moment(day.date).add(1, 'days');

            day.date = tomorrow.toDate();
            day.location_a = dayObj.location_b;
            days.push(day);
            this.setState({
                days: days
            })
        }
    };

    dateChangeHandler = index => date => {
        this.setState({
            days: update(this.state.days, {[index]: {date: {$set: date}}}),
        }, () => {
            this.setState({
                dates: this.state.days.map(day => (day.date))
            })
        });
    };

    dayChangeHandler = index => e => {
        this.setState({
            days: update(this.state.days, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };

    addDayCkEditorDescription = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            days: update(this.state.days, {[index]: {description: {$set: data}}})
        })
    };

    addDayCkEditorRemarks = index => e => {
        const data = e.editor.getData();
        this.setState({
            days: update(this.state.days, {[index]: {remarks: {$set: data}}})
        })
    };

    dayFileChange = index => event => {
        this.setState({
            days: update(this.state.days, {[index]: {[event.target.name]: {$set: event.target.files[0]}}})
        })
    };

    clickCheck = index => e => {
        if (this.state.days[index].services.includes(parseInt(e.target.value))) {
            let services = [...this.state.days[index].services];
            services.splice(services.indexOf(parseInt(e.target.value)), 1);
            this.setState({
                days: update(this.state.days, {[index]: {services: {$set: services}}})
            })
        } else {
            this.setState({
                days: update(this.state.days, {[index]: {services: {$set: [...this.state.days[index].services].concat(parseInt(e.target.value))}}})
            })
        }
    };

    handleClickAdditionalLocations = index => e => {
        if ("additional_locations" in this.state.days[index]) {
            if (this.state.days[index].additional_locations.includes(parseInt(e.target.value))) {
                let additional_locations = [...this.state.days[index].additional_locations];
                additional_locations.splice(additional_locations.indexOf(parseInt(e.target.value)), 1);
                this.setState({
                    days: update(this.state.days, {[index]: {additional_locations: {$set: additional_locations}}})
                })
            } else {
                this.setState({
                    days: update(this.state.days, {[index]: {additional_locations: {$set: [...this.state.days[index].additional_locations].concat(parseInt(e.target.value))}}})
                })
            }
        }
    };

    removeDay = (index) => {
        const dates = [...this.state.dates];
        let days = [...this.state.days];
        const date = days[index].date;
        for (let i = 0; i < dates.length; i++) {
            if (dates[i] === date) {
                dates.splice(i, 1);
            }
        }
        let count = index + 1;
        for(let i = count; i < days.length; i++) {
            const day = {...days[i]};
            let nextDay = moment(day.date).subtract(1, 'days');
            days[i].date = nextDay.toDate();
        }
        days.splice(index, 1);
        this.setState({days, dates})
    };
    /////

    /// HANDLERS FOR TOUR
    deletePriceHandler = item => {
        let data = this.state.tour.price_and_pax.data;
        const index = data.findIndex(element => element === item);
        data.splice(index, 1);
        this.setState({
            tour: {
                ...this.state.tour,
                price_and_pax: {...this.state.tour.price_and_pax, data: data}
            }
        })
    };

    addPriceAndPaxHandler = () => {
        const object = {
            pax: '',
            price: ''
        };
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: this.state.tour.price_and_pax.data.concat(object)
                    }
            }
        })
    };

    changeCurrencyHandler = currency => {
        this.setState({
            tour: {
                ...this.state.tour,
                price_and_pax: {
                    ...this.state.tour.price_and_pax,
                    currency: currency
                }
            }
        })
    };

    changePriceHandler = index => event => {
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: update(this.state.tour.price_and_pax.data, {[index]: {[event.target.name]: {$set: event.target.value}}})
                    }
            }
        });
    };

    changePaxHandler = index => (event) => {
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: update(this.state.tour.price_and_pax.data, {[index]: {[event.target.name]: {$set: event.target.value}}})
                    }
            }
        });
    };

    clearExcelInTour = () => {
        this.setState({tour: {...this.state.tour, excel: ''}})
    };

    clearImage = () => {
        this.image = '';
    };

    changeHandler = (e) => {
        this.setState({tour: {...this.state.tour, [e.target.name]: e.target.value}});
    };

    imageChange = (event) => {
        this.imageFile = event.target.files[0];
        this.setState({
            tour: {...this.state.tour, image: event.target.files[0]}
        })
    };

    fileChangeHandler = event => {
        this.setState({tour: {...this.state.tour, [event.target.name]: event.target.files[0]}});
    };

    ckeditorChangeHandler = (e) => {
        const data = e.editor.getData();
        this.setState({tour: {...this.state.tour, description: data}});
    };

    addTourIncludedCk = e => {
        const data = e.editor.getData();
        this.setState({tour: {...this.state.tour, included: data}})
    };

    /////

    //// HANDLERS FOR TEMPLATES

    templateChangeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    findTemplate = () => {
        this.props.getTemplates(this.state.category, this.state.duration);
    };

    getChosenTemplate = event => {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.props.getTemplateInfo(this.state.template)
        })
    };

    /////

    //// FUNCTIONS SUBMIT
    formatDate = date => {
        let d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    submitFormHandler = event => {
        event.preventDefault();

        let image;

        if (this.imageUrl !== '' && this.imageFile === '') {
            image = decodeURI(this.imageUrl)
        } else if (this.imageFile !== '') {
            image = this.imageFile
        } else {
            image = ''
        }
        const tour = this.state.tour;

        const data = tour.price_and_pax.data;
        let newData = [];
        for (let item of data) {
            let object = {};
            object[item.pax] = item.price;
            newData.push(object);
        }
        tour.price_and_pax.data = newData;

        tour.price_and_pax = JSON.stringify(tour.price_and_pax);
        const formDataTour = new FormData();
        Object.keys(tour).forEach(key => {
            if (key === 'group_size') {
                formDataTour.append(key, parseInt(tour[key]));
            } else if (tour[key] === null) {
                tour[key] = '';
            } else {
                formDataTour.append(key, tour[key]);
            }
        });

        let daysData = [];
        for (let day of this.state.days) {
            let image;
            if (typeof day.image === 'string') {
                image = decodeURI(day.image)
            } else if (day.image === null) {
                image = '';
            } else {
                image = day.image;
            }

            const formDataDay = new FormData();
            formDataDay.append("date", this.formatDate(day.date));
            formDataDay.append("location_a", day.location_a);
            formDataDay.append("location_b", day.location_b);
            formDataDay.append('remarks', day.remarks);
            formDataDay.append('image', image);
            formDataDay.append('description', day.description);
            console.log(day.additional_locations)
            for (let service of day.services) {
                formDataDay.append('services', service)
            }
            if ("additional_locations" in day){
                for (let location of day.additional_locations) {
                    formDataDay.append('additional_locations', location)
                }
            }

            daysData.push(formDataDay);
        }

        let filesData = [];
        for (let file of this.state.files) {
            const formDataFile = new FormData();
            formDataFile.append('file', file.file);
            filesData.push(formDataFile);
        }

        const tourData = {
            tour: formDataTour,
            days: daysData,
            files: filesData,
            eurInvoice: this.state.eurInvoice,
            usdInvoice: this.state.usdInvoice,
            usdDetails: this.state.usdDetails,
            eurDetails: this.state.eurDetails
        };
        this.props.createTour(tourData, this.props.history);
    };

    render() {
        return (
            <Fragment>
                {this.props.touristData && !this.props.isLoading ?
                    <Row>
                        <Col className="col-8 offset-sm-2">
                            <h1 className="page_title">Create tour for <NavLink style={{textDecoration: 'none'}}
                                                                                to={`/tourist/${this.id}`}>
                                {this.props.touristData.full_name + ' '}
                            </NavLink>
                                <div className="line_helper"></div>
                            </h1>

                            <div className="alert alert-secondary" role="alert">
                                You can choose tours' data from templates.
                            </div>

                            {this.props.tourMetas ?
                                <ChooseTemplateForm
                                    findTemplate={this.findTemplate}
                                    templateChangeHandler={this.templateChangeHandler}
                                    duration={this.state.duration}
                                    category={this.state.category}
                                    tourMetas={this.props.tourMetas}
                                    checkDisabled={this.checkDisabled}
                                />
                                : null}

                            {this.props.templates ?
                                <Input type='select'
                                       name='template'
                                       value={this.state.template}
                                       onChange={this.getChosenTemplate}
                                >
                                    <option>Choose template</option>
                                    {this.props.templates.tours.map(template => {
                                        return (
                                            <option key={template.id} value={template.id}>{template.title}</option>
                                        )
                                    })}
                                </Input> : null
                            }

                            <div className="line_helper"></div>


                            <Form onSubmit={this.submitFormHandler}>

                                <div className="tour_part_1_wrapper">
                                    <AddFormTour
                                        clearExcelInTour={this.clearExcelInTour}
                                        clearImage={this.clearImage}
                                        myRef={this.myRef}
                                        tour={this.state.tour}
                                        imageChange={this.imageChange}
                                        changeHandler={this.changeHandler}
                                        fileChange={this.fileChangeHandler}
                                        ckEditor={this.ckeditorChangeHandler}
                                        submitHandler={this.submitFormHandler}
                                        tourMetas={this.props.tourMetas}
                                        imageUrl={this.imageUrl}
                                    />
                                </div>


                                <div className="line_helper"></div>

                                <div className="days_part_wrapper">
                                    <h2 className="form_name">Add day</h2>
                                    {this.state.days && this.state.days.map((day, index) => {
                                        return (
                                            <AddDayForm
                                                count={index + 1}
                                                removeImageInDay={this.removeImageInDay(index)}
                                                dates={this.state.dates}
                                                removeDay={() => this.removeDay(index)}
                                                key={index}
                                                setDate={this.dateChangeHandler(index)}
                                                handleClick={this.clickCheck(index)}
                                                day={day}
                                                dayFileChange={this.dayFileChange(index)}
                                                addDayCkEditorDescription={this.addDayCkEditorDescription(index)}
                                                addDayCkEditorRemarks={this.addDayCkEditorRemarks(index)}
                                                dayChange={this.dayChangeHandler(index)}
                                                handleClickAdditionalLocations={this.handleClickAdditionalLocations(index)}
                                                tourMetas={this.props.tourMetas}
                                            />
                                        )
                                    })}
                                    <FormGroup>
                                        <Button color='primary' onClick={this.addNewDayHandler}>Add another day</Button>
                                    </FormGroup>
                                </div>


                                <div className="line_helper"></div>

                                <div className="files_part_wrapper">
                                    <h2 className="form_name">Add File</h2>
                                    {this.state.files.map((file, index) => {
                                        return (
                                            <CreateFileForm
                                                removeFileInFiles={this.removeExcelInFiles(index)}
                                                removeFile={this.removeFile}
                                                key={index}
                                                fileChange={this.pdfFileChange(index)}
                                            />
                                        )
                                    })}
                                    <FormGroup>
                                        <Button color='primary' onClick={this.addFile}>Add another file</Button>
                                    </FormGroup>
                                </div>


                                <div className="line_helper"></div>

                                <div className="price_part_wrapper">
                                    <CreateTourPrice
                                        changePaxHandler={this.changePaxHandler}
                                        deletePrice={this.deletePriceHandler}
                                        changeCurrencyHandler={this.changeCurrencyHandler}
                                        changePriceHandler={this.changePriceHandler}
                                        data={this.state.tour.price_and_pax}
                                        addPriceAndPaxHandler={this.addPriceAndPaxHandler}
                                        tour={this.state.tour}
                                        addTourIncludedCk={this.addTourIncludedCk}
                                    />
                                </div>

                                <div className="line_helper"></div>

                                <div className="invoice_part_wrapper">
                                    <h2 className="form_name">Add invoices</h2>
                                    <InvoicesTabs
                                        deleteUsdDetail={(index) => this.deleteUsdDetail(index)}
                                        deleteEurDetail={(index) => this.deleteEurDetail(index)}
                                        usdChangeHandler={this.usdChangeHandler}
                                        eurChangeHandler={this.eurChangeHandler}
                                        usdDetailChangeHandler={this.usdDetailChangeHandler}
                                        eurDetailChangeHandler={this.eurDetailChangeHandler}
                                        usdDetails={this.state.usdDetails}
                                        eurDetails={this.state.eurDetails}
                                        usdCkEditorDetail={this.usdCkEditorDetail}
                                        eurCkEditorDetail={this.eurCkEditorDetail}
                                        addEurDetail={this.addEurDetail}
                                        addUsdDetail={this.addUsdDetail}
                                    />
                                </div>

                                <div className="line_helper"></div>

                                <FormGroup>
                                    <Button type='submit' color='success'>Save tour</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row> : <Preloader/>
                }

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    tourMetas: state.tours.tourMetas,
    touristData: state.tr.touristInfo,
    isLoading: state.tr.isLoading,
    templates: state.templates.templates,
    templateInfo: state.templates.templateInfo
});

const mapDispatchToProps = dispatch => ({
    getTourMetas: () => dispatch(getTourMetas()),
    createTour: (tourData, history) => dispatch(createTour(tourData, history)),
    getTouristInfo: (id) => dispatch(getTouristInfo(id)),
    getTemplates: (category, duration) => dispatch(getTemplates(category, duration)),
    getTemplateInfo: id => dispatch(getTemplateInfo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTour);
