import React, {Fragment} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';

const FormElement = (props) => {
    return (
        <Fragment>
            {props.fields.map((field, index) => {
                if (field.required === true) {
                    return (
                        <FormGroup key={index}>
                            <Label for={field.id}>{field.label}{field.required ? ' * ' : ''}</Label>
                            <Input
                                required
                                id={field.id}
                                type={field.type}
                                value={!props.state[field.name] ? '' : props.state[field.name]}
                                name={field.name}
                                onChange={props.change}
                            />
                        </FormGroup>
                    )
                } else {
                    return (
                        <FormGroup key={index}>
                            <Label for={field.id}>{field.label}{field.required ? ' * ' : ''}</Label>
                            <Input
                                id={field.id}
                                type={field.type}
                                value={!props.state[field.name] ? '' : props.state[field.name]}
                                name={field.name}
                                onChange={props.change}
                            />
                        </FormGroup>
                    )
                }
            })}
        </Fragment>
    );
};

export default FormElement;
