import {
    GET_TOUR_METAS_SUCCESS,
    GET_TOUR_ERROR,
    GET_TOURISTS_TOURS_REQUEST,
    GET_TOUR_METAS_REQUEST,
    GENERATE_PDF_ERROR,
    GET_TOUR_DETAILS_REQUEST,
    GET_TOUR_DETAILS_ERROR,
    GET_TOUR_DETAILS_SUCCESS,
    GET_TOURS_INVOICES_SUCCESS,
    GET_TOURS_INVOICES_ERROR,
    UPDATE_TOUR_ERROR,
    UPDATE_TOUR_REQUEST,
    DELETE_FILE_ERROR
} from "./actionTypes";
import axios from '../../axios-api';
import {NotificationManager} from "react-notifications";

export const getTourMetasSuccess = data => {
    return {type: GET_TOUR_METAS_SUCCESS, data};
};

export const getTourError = error => {
    return {type: GET_TOUR_ERROR, error};
};

const getTouristsToursRequest = () => {
    return {type: GET_TOURISTS_TOURS_REQUEST}
};

export const errorHandler = (error) => {
    if(error.status === 404 || error.status === 400) {
        NotificationManager.error("Error", error.response.data.detail)
    } else {
        NotificationManager.error('Error', 'Something went wrong!')
    }
};

export const getTourMetas = () => {
    return async dispatch => {
        dispatch(getTouristsToursRequest());
        try {
            const response = await axios.get('/api/v1/tours/metas/');
            dispatch(getTouristsToursRequest());
            dispatch(getTourMetasSuccess(response.data[0]));
        } catch (e) {
            dispatch(getTouristsToursRequest());
            dispatch(getTourError());
            dispatch(errorHandler(e))
        }
    };
};

const getTourMetasRequest = () => {
    return {type: GET_TOUR_METAS_REQUEST}
};

const createDays = (days, id) => {
    return async dispatch => {
        try {
            for(let day of days) {
                day.append('tour', id);
                await axios.post(`/api/v1/days/`, day);
            }
        } catch (error) {
            dispatch(errorHandler(error))
        }
    }
};

const createFiles = (files, id) => {
    return async dispatch => {
        try {
            for(let file of files) {
                file.append('tour', id);
                await axios.post(`/api/v1/tours_files/`, file);
            }
        } catch (error) {
            dispatch(errorHandler(error));
        }
    }
};

const createInvoice = (invoice, details) => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/invoices/', invoice);
            for(let detail of details) {
                detail.invoice = response.data.id;
                await axios.post('/api/v1/invoices_detail/', detail);
            }
        } catch (error) {
            dispatch(errorHandler(error))
        }
    }
};

export const createTour = (tourData, history) => {
    return async dispatch => {
        let tourId;
        dispatch(getTourMetasRequest());
        try {
            const tour = await axios.post('/api/v1/tours/', tourData.tour);
            tourId = tour.data.id;
            let days = tourData.days;
            let files = tourData.files;

            await dispatch(createDays(days, tourId));
            await dispatch(createFiles(files, tourId));

            parseInt(tourData.eurInvoice.sum);
            parseInt(tourData.usdInvoice.sum);
            tourData.eurInvoice.tour = tourId;
            tourData.usdInvoice.tour = tourId;

            await dispatch(createInvoice(tourData.eurInvoice, tourData.eurDetails));
            await dispatch(createInvoice(tourData.usdInvoice, tourData.usdDetails));

            await dispatch(generatePdf(tourId));
            await dispatch(getTourMetasRequest());
            history.push(`/tour_edit/${tourId}`);
        } catch (e) {
            dispatch(getTourMetasRequest());
            dispatch(getTourError());
            dispatch(errorHandler(e))
        }
    }
};

export const generatePdfError = error => ({type: GENERATE_PDF_ERROR, error});

export const generatePdf = (tourId) => {
    return async dispatch => {
        try {
            await axios.post(`/api/v1/generate_pdf/${tourId}/`);
        } catch (e) {
            dispatch(generatePdfError(e))
        }
    }
};

const deleteFileError = error => ({type: DELETE_FILE_ERROR, error});

export const deleteFile = (id) => {
    return async dispatch => {
        try {
            await axios.delete(`/api/v1/tours_files/${id}/`);
        } catch (e) {
            dispatch(deleteFileError(e))
        }
    }
};

export const getTourDetailsRequest = () => ({type: GET_TOUR_DETAILS_REQUEST});

export const getTourDetailsSuccess = (data) => ({type: GET_TOUR_DETAILS_SUCCESS, data});
export const getTourDetailsError = error => ({type: GET_TOUR_DETAILS_ERROR, error});

export const getTourDetail = id => {
    return dispatch => {
        dispatch(getTourDetailsRequest());
        return axios.get(`/api/v1/tours/${id}/`).then(response => {
            if (response.data.days) {
                response.data.days = response.data.days.map(day => ({
                    ...day,
                    date: new Date(day.date),
                }));
            }
            dispatch(getTourDetailsRequest());
            dispatch(getTourDetailsSuccess(response.data))
        }, error => {
            dispatch(getTourDetailsError(error));
            dispatch(getTourDetailsRequest());
        })
    }
};


export const getToursInvoicesSuccess = data => ({type: GET_TOURS_INVOICES_SUCCESS, data});
export const getToursInvoicesError = error => ({type: GET_TOURS_INVOICES_ERROR, error});

export const getToursInvoices = id => {
    return dispatch => {
        return axios.get(`/api/v1/tours/${id}/invoices/`).then(response => {
            dispatch(getToursInvoicesSuccess(response.data))
        }, error => {
            dispatch(getToursInvoicesError(error));
        })
    }
};


const updateTourRequest = () => ({type: UPDATE_TOUR_REQUEST});
const updateTourError = error => ({type: UPDATE_TOUR_ERROR, error});

const formatDate = date => {
    let d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

const sendDays = (days, id) => {
    return async dispatch => {
        for (let day of days) {
            let dayId = day.id;
            const formDataDay = new FormData();
            if(day.image === null || day.image === '') {
                day.image = '';
                formDataDay.append('image', day.image);
            } else if (typeof day.image === 'object') {
                formDataDay.append('image', day.image);
            }
            formDataDay.append('location_a', day.location_a);
            formDataDay.append('location_b', day.location_b);
            formDataDay.append('date', formatDate(day.date));
            formDataDay.append('description', day.description);
            formDataDay.append('tour', id);
            formDataDay.append('remarks', day.remarks);
            for (let service of day.services) {
                formDataDay.append('services', service);
            }
            for (let location of day.additional_locations) {
                formDataDay.append('additional_locations', location);
            }

            if (day.id) {
                delete day.id;
                await axios.put(`/api/v1/days/${dayId}/`, formDataDay);
            } else {
                await axios.post(`/api/v1/days/`, formDataDay);
            }

        }
    }
};

export const updateTour = (id, tourData, location) => {
    return async dispatch => {
        dispatch(updateTourRequest());
        try {

            await axios.put(`/api/v1/tours/${id}/`, tourData.tour);

            const days = tourData.days;
            await dispatch(sendDays(days, id));

            for (let file of tourData.files) {

                if(typeof file.file === 'string' && file.file.length > 0) {
                    file.file = '';
                }
                if (file.id) {
                    let fileId;
                    fileId = file.id;
                    const formData = new FormData();
                    formData.append('tour', file.tour);
                    formData.append('file', file.file);
                    delete file.id;
                    await axios.put(`/api/v1/tours_files/${fileId}/`, formData);
                } else {
                    const formData = new FormData();
                    formData.append('tour', file.tour);
                    formData.append('file', file.file);
                    await axios.post('/api/v1/tours_files/', formData);
                }
            }

            let eurId;
            if (tourData.eurInvoice !== null) {
                eurId = tourData.eurInvoice.id;
                delete tourData.eurInvoice.pdf;
                delete tourData.eurInvoice.details;
                delete tourData.eurInvoice.id;
                await axios.put(`/api/v1/invoices/${eurId}/`, tourData.eurInvoice);
            } else {
                const invoice = {
                    type: 1,
                    tour: id
                };
                await axios.post('/api/v1/invoices/', invoice)
            }

            let usdId;
            if (tourData.usdInvoice !== null) {
                usdId = tourData.usdInvoice.id;
                delete tourData.usdInvoice.pdf;
                delete tourData.usdInvoice.details;

                if (tourData.usdInvoice.id) {
                    delete tourData.usdInvoice.id;
                    await axios.put(`/api/v1/invoices/${usdId}/`, tourData.usdInvoice);
                }
            } else {
                const invoice = {
                    type: 2,
                    tour: id
                };
                await axios.post('/api/v1/invoices/', invoice)
            }

            for (let detail of tourData.eurDetails) {
                let detailId;
                detailId = detail.id;
                detail.invoice = eurId;

                if(detail.id) {
                    delete detail.id;
                    await axios.put(`/api/v1/invoices_detail/${detailId}/`, detail);
                } else {
                    await axios.post('/api/v1/invoices_detail/', detail);
                }

            }

            for (let detail of tourData.usdDetails) {
                let detailId;
                detailId = detail.id;
                detail.invoice = usdId;

                if(detail.id) {
                    delete detail.id;
                    await axios.put(`/api/v1/invoices_detail/${detailId}/`, detail);
                } else {
                    await axios.post('/api/v1/invoices_detail/', detail);
                }
            }
            dispatch(generatePdf(id));
            location.reload();
        } catch (e) {
            dispatch(updateTourError(e));
            NotificationManager.error("Error", 'Can not update tour');
        }
    }
};

export const deleteDay = id => {
    return async dispatch => {
        try {
            await axios.delete(`/api/v1/days/${id}/`);
        } catch (e) {
            NotificationManager.error('Error', "Can not delete day");
        }
    }
};
