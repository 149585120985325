import React, {Component, Fragment} from 'react';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css';
import {NavLink} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";

const $ = require('jquery');
$.DataTable = require('datatables.net-fixedcolumns-bs4')
const myRef = React.createRef();

class DriversDashboardTable extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        drivers: null,
        months: null,
        sorted: null,
        weeks: null,
        tooltipOpen: false
    };

    dataTable = null;

    componentDidMount() {
        if (this.props.drivers.dates.length !== 0) {
            setTimeout(() => {
                this.dataTable = $(myRef.current).DataTable({
                    scrollY: false,
                    scrollX: true,
                    scrollCollapse: true,
                    paging: false,
                    ordering: false,
                    searching: false,
                    info: false,
                    fixedColumns: {
                        leftColumns: 3,
                    }
                });
                // $('tr[data-index]').on('mouseenter', (e) => {
                //     const index = parseInt(e.currentTarget.dataset.index);
                //     this.eventMouse(index);
                // });
                //
                // $('tr[data-index]').on('mouseleave', () => {
                //     this.leaveMouse()
                // })
            }, 0);

            this.setState({
                drivers: this.props.drivers,
                sorted: this.props.sorted,
                months: this.props.months,
                weeks: this.props.weeks
            })
        }
    }

    componentWillUnmount() {
        if (this.props.drivers.dates.length !== 0) {
            $(myRef.current).DataTable()
                .clear()
                .destroy();
        }
    }

    // eventMouse = index => {
    //     const drivers = [...this.props.drivers.orders];
    //     const data = drivers[index];
    //
    //     if (data) {
    //         $('.cash').text(data.cash ? `Cash: ${data.cash}` : '');
    //         $('.cashless').text(data.cashless ? `Cashless: ${data.cashless}` : '');
    //         $('.estimate').text(data.estimate ? `Estimate: ${data.estimate}` : '');
    //     }
    // };
    //
    // leaveMouse = () => {
    //     $('.cash').text('');
    //     $('.cashless').text('');
    //     $('.estimate').text('');
    // };


    render() {
        return (
            <table ref={myRef} id="drivers_dashboard" className="table table-bordered nowrap">
                {this.state.drivers && <Fragment>
                    <thead>
                    <tr>
                        <th colSpan="3"></th>
                        {this.state.months.map((item, index) => {
                            const month = this.state.sorted[item];
                            return (
                                <th key={`month_td_${index += 1}_${month.daysCount}`} className="month_td"
                                    colSpan={month.daysCount}>{month.month}</th>
                            )
                        })}
                    </tr>
                    <tr>
                        <th colSpan="3"></th>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return (
                                <th key={`week_td_${index += 1}`} className="week_td"
                                    colSpan={this.state.weeks[week].length}>W{this.state.weeks[week][0].week}</th>
                            )
                        })}
                    </tr>
                    <tr>
                        <th rowSpan="2">Code</th>
                        <th rowSpan="2">Tourist & Tour</th>
                        <th rowSpan="2">Driver</th>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return this.state.weeks[week].map((obj) => {
                                return (
                                    <th key={`square_td_namedDay_${index += 1}`}
                                        className={obj.namedDay.toLowerCase() + ' square_td'}>
                                        <div className="square">{obj.namedDay}</div>
                                    </th>
                                )
                            })
                        })}
                    </tr>

                    <tr>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return this.state.weeks[week].map((obj) => {
                                return (
                                    <th key={`square_td_day_${index += 1}`}
                                        className={obj.namedDay.toLowerCase() + ' square_td'}>
                                        <div className="square">{obj.day}</div>
                                    </th>
                                )
                            })
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {this.state.drivers && this.state.drivers.orders.map((item, index) => {
                        return (
                            <tr data-index={index} key={`drivers_${index + 1}`} className='tour-data'>
                                <td>
                                    <NavLink className='plan_link'
                                             to={`/tour_edit/${item.tour}`}>T({item.tour})</NavLink>
                                </td>
                                <td>
                                    <div id={`driver_info_${item.id}`} data-foo={item}>
                                        <span className="dashboard_tourist_name">{item.tourist}</span>
                                        <span className="dashboard_tour_title">{item.tour_title}</span>
                                    </div>
                                </td>
                                <td className={item.type}>{item.provider}</td>

                                {Object.keys(this.state.weeks).map((week, index) => {
                                    return this.state.weeks[week].map((obj) => {
                                        if (moment(obj.date).isBetween(item.start_date, item.end_date, null, '[]')) {
                                            return (
                                                <td key={`square_current_${index += 1}`}
                                                    className={obj.namedDay.toLowerCase() + ' square_td'}>
                                                    <div
                                                        className={item.is_canceled ? "square canceled" : "square current"}>
                                                    </div>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td key={`square_${index += 1}`}
                                                className={obj.namedDay.toLowerCase() + ' square_td'}>
                                                <div className="square"></div>
                                            </td>
                                        )
                                    })
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </Fragment>}
            </table>
        );
    }
}

export default DriversDashboardTable;
