import React, {Component, Fragment} from 'react';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css';
import "react-datepicker/dist/react-datepicker.css"
import {NavLink} from "react-router-dom";
import moment from "moment";

const $ = require('jquery');
$.DataTable = require('datatables.net-fixedcolumns-bs4');
const myRef = React.createRef();

class GuidesAndDriversTable extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        data: null,
        months: null,
        sorted: null,
        weeks: null,
    };

    dataTable = null;

    componentDidMount() {
        if (this.props.data.dates.length !== 0) {
            setTimeout(() => {
                this.dataTable = $(myRef.current).DataTable({
                    scrollY: false,
                    scrollX: true,
                    scrollCollapse: true,
                    paging: false,
                    ordering: false,
                    searching: false,
                    info: false,
                    fixedColumns: {
                        leftColumns: 3,
                    }
                });
                $('tr[data-index]').on('mouseenter', (e) => {
                    const item = JSON.parse(e.currentTarget.dataset.index);
                    this.eventMouse(item);
                });

                $('tr[data-index]').on('mouseleave', () => {
                    this.leaveMouse()
                })
            }, 0);

            this.setState({
                data: this.props.data,
                sorted: this.props.sorted,
                months: this.props.months,
                weeks: this.props.weeks
            })
        }
    }

    componentWillUnmount() {
        if (this.props.data.dates.length !== 0) {
            $(myRef.current).DataTable()
                .clear()
                .destroy();
        }
    }

    eventMouse = item => {
        if(item.status === 'guide') {
            const guides = [...this.props.data.orders.guides];
            const object = guides[item.index]
            if (object) {
                $('.tourist_name').text(object.tourist ? `${object.tourist}` : '');
                $('.tour_name').text(object.tour_title ? `Tour: ${object.tour_title}` : '');
            }
        } else if (item.status === 'driver') {
            const drivers = [...this.props.data.orders.drivers];
            const object = drivers[item.index]
            if (object) {
                $('.tourist_name').text(object.tourist ? `Tourist: ${object.tourist}` : '');
                $('.tour_name').text(object.tour_title ? `Tour: ${object.tour_title}` : '');
            }
        }
    };

    leaveMouse = () => {
        $('.tourist_name').text('');
        $('.tour_name').text('');
    };

    render() {
        return (
            <table ref={myRef} id="guides_dashboard" className="table table-bordered nowrap">
                {this.state.data && <Fragment>
                    <thead>
                    <tr>
                        <th colSpan="3"></th>
                        {this.state.months.map((item, index) => {
                            const month = this.state.sorted[item];
                            return (
                                <th key={`month_td_${index += 1}_${month.daysCount}`} className="month_td"
                                    colSpan={month.daysCount}>{month.month}</th>
                            )
                        })}
                    </tr>
                    <tr>
                        <th colSpan="3">
                            <span className='data-span'><span className='tourist_name'></span></span>
                            <br/>
                            <span className='data-span'><span className='tour_name'></span></span>
                        </th>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return (
                                <th key={`week_td_${index += 1}`} className="week_td"
                                    colSpan={this.state.weeks[week].length}>W{this.state.weeks[week][0].week}</th>
                            )
                        })}
                    </tr>
                    <tr>
                        <th rowSpan="2">Code</th>
                        <th rowSpan="2">Provider</th>
                        <th rowSpan='2'>Status</th>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return this.state.weeks[week].map((obj) => {
                                return (
                                    <th key={`square_td_namedDay_${index += 1}`}
                                        className={obj.namedDay.toLowerCase() + ' square_td'}>
                                        <div className="square">{obj.namedDay}</div>
                                    </th>
                                )
                            })
                        })}
                    </tr>

                    <tr>
                        {Object.keys(this.state.weeks).map((week, index) => {
                            return this.state.weeks[week].map((obj) => {
                                return (
                                    <th key={`square_td_day_${index += 1}`}
                                        className={obj.namedDay.toLowerCase() + ' square_td'}>
                                        <div className="square">{obj.day}</div>
                                    </th>
                                )
                            })
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {this.state.data && this.state.data.orders.drivers.map((item, index) => {
                        return (
                            <tr data-index={JSON.stringify({index: index, status: item.status})} key={`accoms_${index + 1}`} className='tour-data'>
                                <td>
                                    <NavLink className='plan_link'
                                             to={`/tour_edit/${item.tour}`}>T({item.tour})</NavLink>
                                </td>
                                <td className={item.type}>{item.provider}</td>
                                <td>Driver</td>

                                {Object.keys(this.state.weeks).map((week, index) => {
                                    return this.state.weeks[week].map((obj) => {
                                        if (moment(obj.date).isBetween(item.start_date, item.end_date, null, '[]')) {
                                            return (
                                                <td key={`square_current_${index += 1}`}
                                                    className={obj.namedDay.toLowerCase() + ' square_td '}>
                                                    <div
                                                        className={item.is_canceled ? "square canceled" : "square current "}>
                                                    </div>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td key={`square_${index += 1}`}
                                                className={obj.namedDay.toLowerCase() + ' square_td'}>
                                                <div className="square"></div>
                                            </td>
                                        )
                                    })
                                })}
                            </tr>
                        )
                    })}
                    {this.state.data && this.state.data.orders.guides.map((item, index) => {
                        return (
                            <tr data-index={JSON.stringify({index: index, status: item.status})} key={`accoms_${index + 1}`} className='tour-data'>
                                <td>
                                    <NavLink className='plan_link'
                                             to={`/tour_edit/${item.tour}`}>T({item.tour})</NavLink>
                                </td>
                                <td className={item.type}>{item.provider}</td>
                                <td>Guide</td>

                                {Object.keys(this.state.weeks).map((week, index) => {
                                    return this.state.weeks[week].map((obj) => {
                                        if (moment(obj.date).isBetween(item.start_date, item.end_date, null, '[]')) {
                                            return (
                                                <td key={`square_current_${index += 1}`}
                                                    className={obj.namedDay.toLowerCase() + ' square_td '}>
                                                    <div
                                                        className={item.is_canceled ? "square canceled" : "square current "}>
                                                    </div>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td key={`square_${index += 1}`}
                                                className={obj.namedDay.toLowerCase() + ' square_td'}>
                                                <div className="square"></div>
                                            </td>
                                        )
                                    })
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </Fragment>}
            </table>
        );
    }
}

export default GuidesAndDriversTable;
