import React from 'react';
import {Label, FormGroup, Input, Row, Col, Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const myRef = React.createRef();

const CreateFileForm = props => {

    const clearFile = () => {
        myRef.current.value = null;

        props.removeFileInFiles();
    };

    return (
        <Row className="add_file_form_wrapper">
            <Col>
                <FormGroup>
                    <Label>File</Label>
                    <div className="tour_attachment_wrapper">
                        <Input type='file'
                               onChange={props.fileChange}
                               name='file'
                               innerRef={myRef}
                        />
                    </div>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default CreateFileForm;
