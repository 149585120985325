import React, {useState} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, FormGroup, Button } from 'reactstrap';
import classnames from 'classnames';
import InvoiceDetailEurForm from "../../CreateTourForms/InvoiceDetailForm/InvoiceDetailEurForm";
import InvoiceDetailUsdForm from "../../CreateTourForms/InvoiceDetailUsdForm/InvoiceDetailUsdForm";

const InvoicesTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="add_invoice_form_wrapper">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        EUR
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        USD
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    {props.eurDetails && props.eurDetails.length > 0 ? props.eurDetails.map((detail, index) => {
                        return (
                            <Row key={index}>
                                <Col>
                                    <InvoiceDetailEurForm
                                        deleteEurDetail={() => props.deleteEurDetail(index)}
                                        disabled={props.disabled}
                                        sum={detail.sum}
                                        deal={detail.deal}
                                        detail={detail.detail}
                                        eurDetailChangeHandler={props.eurDetailChangeHandler(index)}
                                        eurCkEditorDetail={props.eurCkEditorDetail(index)}
                                    />
                                </Col>
                            </Row>
                        )
                    }) : null}

                    {!props.disabled ? <FormGroup>
                        <Row>
                            <Col>
                                <Button onClick={props.addEurDetail} className="mt30">Add invoice detail</Button>
                            </Col>
                        </Row>
                    </FormGroup> : null}
                </TabPane>

                <TabPane tabId="2">
                    {props.usdDetails && props.usdDetails.length > 0 ? props.usdDetails.map((detail, index) => {
                        return (
                            <Row key={index}>
                                <Col>
                                    <InvoiceDetailUsdForm
                                        deleteUsdDetail={() => props.deleteUsdDetail(index)}
                                        disabled={props.disabled}
                                        sum={detail.sum}
                                        deal={detail.deal}
                                        detail={detail.detail}
                                        usdDetailChangeHandler={props.usdDetailChangeHandler(index)}
                                        usdCkEditorDetail={props.usdCkEditorDetail(index)}
                                    />
                                </Col>
                            </Row>
                        )
                    }) : null}

                    {!props.disabled ? <FormGroup>
                        <Row>
                            <Col>
                                <Button
                                    className='add-invoice-btn mt30'
                                    onClick={props.addUsdDetail}
                                >Add invoice detail</Button>
                            </Col>
                        </Row>
                    </FormGroup> : null}
                </TabPane>
            </TabContent>
        </div>
    );
};

export default InvoicesTabs;
