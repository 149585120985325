import {LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, REFRESH_TOKEN_SUCCESS, LOGIN_USER_REQUEST, LOGOUT_USER, DESTROY_SESSION} from "../actions/actionTypes";

const initialState = {
    user: null,
    error: null,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_USER_SUCCESS:
            return {...state, user: action.data};
        case LOGIN_USER_ERROR:
            return {...state, error: action.error};
        case LOGIN_USER_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case REFRESH_TOKEN_SUCCESS:
            return {...state, user: {...state.user, access: action.token}};
        case LOGOUT_USER:
            return {...state, user: null};
        case DESTROY_SESSION:
            return {...state, user: null};
        default:
            return state
    }
};

export default reducer;
