import customConst from '../../global.js';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Form, FormGroup, Button, Label, Input} from 'reactstrap';

import {getTouristInfo, updateTourist} from "../../store/actions/touristsActions";
import FormElement from "../../components/UI/FormElement/FormElement";
const fields = customConst.touristFields;

class EditTourist extends Component {

    state = {
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        note: '',
        is_partner: false,
    };

    changeHandler = e => {
        if (e.target.name === 'is_partner'){
            this.setState({[e.target.name]: e.target.checked});
        } else {
            this.setState({[e.target.name]: e.target.value});

        }
    };

    ckeditorChangeHandler = (e,editor) => {
        const data = editor.getData();
        this.setState({note: data});
    };

    submitHandler = e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        const history = this.props.history;
        this.props.updateTourist(this.state, id, history);
    };

    componentDidMount() {
        this.props.getTouristInfo(this.props.match.params.id).then(() => {
            this.setState(() => this.props.touristInfo)
        })
    }

    render() {
        return (
            <Fragment>
                <div className='col-md-8 offset-md-2'>
                    <div>
                        <h1 className="page_title">Edit {this.state.full_name}</h1>
                    </div>
                    <Form onSubmit={this.submitHandler}>
                        <FormElement
                            fields={fields}
                            change={this.changeHandler}
                            ckeditor_change={this.ckeditorChangeHandler}
                            clickHandler={this.submitHandler}
                            state={this.state}
                            send={'Save'}
                        />

                        <FormGroup key={'is_partner'} className={'form-check'}>
                            <Input
                                id={'is_partner'}
                                type={'checkbox'}
                                value={this.state.is_partner}
                                checked={this.state.is_partner}
                                name={'is_partner'}
                                onChange={this.changeHandler}
                                className={'form-check-input'}
                            />
                            <Label
                                for={'is_partner'}
                                className={'form-check-label'}
                            >Is partner?</Label>
                        </FormGroup>

                        <FormGroup>
                            <Button type='submit' color='primary'>Save</Button>
                        </FormGroup>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    touristInfo: state.tr.touristInfo
});

const mapDispatchToProps = dispatch => ({
    updateTourist: (data, id, history) => dispatch(updateTourist(data, id, history)),
    getTouristInfo: (id) => dispatch(getTouristInfo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTourist);
