import {GET_TOURISTS_ERROR,
    GET_TOURISTS_SUCCESS,
    GET_TOURISTS_REQUEST,
    GET_TOURIST_INFO_REQUEST,
    GET_TOURIST_INFO_SUCCESS,
    GET_TOURISTS_TOURS_SUCCESS,
    CREATE_TOURIST_REQUEST
} from "../actions/actionTypes";

const initialState = {
    touristInfo: null,
    touristTours: null,
    tourists: null,
    error: null,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_TOURISTS_SUCCESS:
            return {...state, tourists: action.tourists};
        case GET_TOURISTS_ERROR:
            return {...state, error: action.error};
        case GET_TOURISTS_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case GET_TOURIST_INFO_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case CREATE_TOURIST_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case GET_TOURIST_INFO_SUCCESS:
            return {...state, touristInfo: action.data};
        case GET_TOURISTS_TOURS_SUCCESS:
            return {...state, touristTours: action.data};
        default:
            return state
    }
};

export default reducer;
