import React, {Fragment, useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabPane, Row, Col} from 'reactstrap';
import classnames from 'classnames';

const ProvidersTabs = props => {
    const [activeTab, setActiveTab] = useState('');
    let providers = [];
    providers.push(props.providers);
    const provs = Object.keys(props.providers).map(key => ({key, ...props.providers[key]}));
    useEffect(() => setActiveTab(provs[0].key), providers);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const renderNavs = () => {
        return provs.map((name, index) => {
            switch (name.key) {
                case 'driver':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Driver
                            </NavLink>
                        </NavItem>
                    );
                case 'home_state':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Home State
                            </NavLink>
                        </NavItem>
                    );
                case 'hotel':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Hotel
                            </NavLink>
                        </NavItem>
                    );
                case 'yurt_camp':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Yurt Camp
                            </NavLink>
                        </NavItem>
                    );
                case 'guest_house':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Guest House
                            </NavLink>
                        </NavItem>
                    );
                case 'extra':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Extra
                            </NavLink>
                        </NavItem>
                    );
                case 'guide':
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                Guide
                            </NavLink>
                        </NavItem>
                    );
                default:
                    return (
                        <NavItem key={index}>
                            <NavLink className={classnames({active: activeTab === name.key})}
                                     onClick={() => {
                                         toggle(name.key);
                                     }}>
                                {name.key}
                            </NavLink>
                        </NavItem>
                    )
            }
        })
    };

    const renderTabs = () => {
        if (!activeTab) return null;
        return (props.providers[activeTab].map((provider, index) => {
                return (
                    <Fragment key={index}>
                        <TabPane>
                            <Row>
                                <Col sm="12">
                                    <h4 onClick={() => props.modalHandler(provider, activeTab)}
                                        className="provider_name"
                                    >
                                        {provider.title}
                                    </h4>
                                </Col>
                            </Row>
                        </TabPane>
                    </Fragment>
                )
            })
        );
    };

    return (
        <Fragment>
            <Nav tabs>
                {renderNavs()}
            </Nav>
            {renderTabs()}
        </Fragment>
    );
};

export default ProvidersTabs;
