import React, {Component} from 'react';
import './App.css';
import Layout from "./components/Layout/Layout";
import Routes from "./Routes";

class App extends Component {
    render() {
        return (
            <Routes/>
        )
    }
}


export default App;
