import React, {Fragment} from 'react';
import {Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css"

const editorConfig = customConst.editorConfig;


const TravelPlanForm = props => {
    let isDisabled = false;
    const user = props.user;
    const plan = props.plan;

    if (user.is_admin === false) {
        if (user.user !== plan.owner) {
            isDisabled = true
        } else {
            isDisabled = false
        }
    }

    if (props.plan.is_canceled === true) {
        if (user.is_admin === true) {
            isDisabled = false
        } else {
            isDisabled = true;
        }
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Form id="travel_plan_form">
                        <Row>
                            <Col>
                                <Label>Arrival date</Label>
                                <FormGroup>
                                    <DatePicker
                                        disabled={isDisabled}
                                        name='arrival_date'
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        selected={props.plan.arrival_date}
                                        onChange={(date) => props.setArrivalDate(date)}
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <Label>Departure date</Label>
                                <FormGroup>
                                    <DatePicker
                                        disabled={isDisabled}
                                        name='departure_date'
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="time"
                                        selected={props.plan.departure_date}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onChange={(date) => props.setDepartureDate(date)}
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='cash_plan'>Cash</Label>
                                    <Input onChange={props.planChangeHandler}
                                           type='number'
                                           id='cash_plan'
                                           name='cash'
                                           value={props.plan.cash === null ? '' : props.plan.cash}
                                           disabled={props.user.is_admin === false ? true : false}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label for='cashless_plan'>Cashless</Label>
                                    <Input onChange={props.planChangeHandler}
                                           type='number'
                                           id='cashless_plan'
                                           name='cashless'
                                           value={props.plan.cashless === null ? '' : props.plan.cashless}
                                           disabled={props.user.is_admin === false ? true : false}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label for='estimate'>Estimate</Label>
                                    <Input onChange={props.planChangeHandler}
                                           type='number'
                                           id='estimate'
                                           name='estimate'
                                           value={props.plan.estimate === null ? '' : props.plan.estimate}
                                           disabled={props.user.is_admin === false ? 'true' : 'false'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                          <Row>
                            <Col>
                                <Label for='country'>Country</Label>
                                <select
                                    value={props.plan.country ? props.plan.country : ""}
                                    onChange={props.setCountry}
                                    name="country"
                                    className="form-control"
                                    required
                                >
                                    <option value="">------</option>
                                    {props.plan.countries.map((option) => (
                                      <option
                                          value={option.id}
                                      >{option.title}</option>
                                    ))}
                                  </select>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for='tourists_number'>Tourists</Label>
                                    <Input onChange={props.planChangeHandler}
                                           type='number'
                                           id='tourists_number'
                                           name='tourists_number'
                                           value={props.plan.tourists_number === null ? 1 : props.plan.tourists_number}
                                           desabled={props.user.is_admin === false ? 'true' : 'false'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label for='plan_note'>Note</Label>
                                <CKEditor
                                    readOnly={isDisabled}
                                    id='plan_note'
                                    type={editorConfig.type}
                                    data={props.plan.note === null ? '' : props.plan.note}
                                    config={editorConfig}
                                    onChange={props.ckEditorChangeTravelPlan}
                                    name='note'
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default TravelPlanForm;
