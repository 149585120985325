import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {NavLink, Redirect} from 'react-router-dom';
import {getTourists} from "../../store/actions/touristsActions";
import Pagination from "react-js-pagination-bs4";
import {Button, Col, Row} from 'reactstrap';
import Preloader from '../../components/UI/Preloader/Preloader';


class MainPage extends Component {

    state = {
        activePage: 1,
        orderBy: false,
        filterBy: false,
    };

    componentDidMount() {
        this.props.getTourists();
    }

    addNewTouristPage = () => {
        this.props.history.push('/create-tourist')
    };


    handlePageChange = (pageNumber) => {
        this.setState({activePage: pageNumber}, () => {
            this.props.getTourists(this.state.activePage, this.state.orderBy, this.state.filterBy)
        });
    };

    handleOrderChange = (orderBy) => {
        this.setState({orderBy: orderBy, activePage: 1}, () => {
            this.props.getTourists(this.state.activePage, this.state.orderBy, this.state.filterBy)
        });
    };

    handleFilterChange = (filterBy) => {
        this.setState({activePage: 1, filterBy: filterBy}, () => {
            this.props.getTourists(this.state.activePage, this.state.orderBy, this.state.filterBy)
        });
    };

    onChange = (date) => {
        this.setState({
            date: date
        });


    };

    render() {
        if (!this.props.user) {
            return (
                <Redirect to='/login'/>
            )
        } else {
            return (
                !this.props.isLoading && this.props.tourists ? <Fragment>
                    <h1 className="page_title">Tourists list</h1>
                    <Row className="mb-3">
                        <Col>
                            <Button color="success" className={this.state.orderBy === "date" ? 'px-4 active' : 'px-4'}
                                    onClick={() => this.handleOrderChange("date")}
                            >
                                By date
                            </Button>
                        </Col>
                        <Col>
                            <Button color="success" className={this.state.orderBy === "name" ? 'px-4 active' : 'px-4'}
                                    onClick={() => this.handleOrderChange("name")}
                            >
                                By name
                            </Button>
                        </Col>
                        <Col>
                            <Button color="success"
                                    className={this.state.filterBy === "partners" ? 'px-4 active' : 'px-4'}
                                    onClick={() => this.handleFilterChange("partners")}
                            >
                                Partners
                            </Button>
                        </Col>
                        <Col>
                            <Button color="success"
                                    className={this.state.filterBy === "individuals" ? 'px-4 active' : 'px-4'}
                                    onClick={() => this.handleFilterChange("individuals")}
                            >
                                Individuals
                            </Button>
                        </Col>
                    </Row>
                    <ul className="list-unstyled custom_list tourist_list">
                        {this.props.tourists && this.props.tourists.results.map(user => {
                            return (
                                <li id={"tourist_" + user.id} key={user.id}>
                                    <NavLink to={`/tourist-info/${user.id}`}>
                                        {user.full_name} {user.is_partner === true ? <b>(partner)</b> :
                                        <b>(individual)</b>}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>

                    {this.props.tourists && this.props.tourists.total_pages > 1 ?
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={50}
                            pageRangeDisplayed={30}
                            totalItemsCount={this.props.tourists.count}
                            onChange={this.handlePageChange}
                        /> : null}

                    <div className="line_helper"></div>

                    <Row>
                        <Col>
                            <Button onClick={this.addNewTouristPage} color='primary'>Add new tourist</Button>
                        </Col>
                    </Row>
                </Fragment> : <Preloader/>
            )
        }
    }
}

const mapStateToProps = state => ({
    tourists: state.tr.tourists,
    user: state.usr.user,
    isLoading: state.tr.isLoading
});

const mapDispatchToProps = dispatch => ({
    getTourists: (page, order_by, filter_by) => dispatch(getTourists(page, order_by, filter_by))
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
