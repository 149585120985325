import React, {Component, Fragment} from 'react';
import axios from '../../axios-api';
import {connect} from 'react-redux';
import {NotificationManager} from 'react-notifications';
import update from "react-addons-update";
import {Button, Col, Form, FormGroup, Row, Alert} from "reactstrap";
import {NavLink} from "react-router-dom";
import InvoicesTabs from "../../components/UI/InvoicesTabs/InvoicesTabs";
import Preloader from "../../components/UI/Preloader/Preloader";
import {deleteDay, deleteFile, getTourDetail, getTourMetas, updateTour} from "../../store/actions/tourActions";
import {closeModal, saveAsTemplate} from '../../store/actions/templatesActions';
import {getTouristInfo} from "../../store/actions/touristsActions";
import EditFormTour from "../../components/EditTourForms/EditTourForm/EditFormTour";
import EditFileForm from "../../components/EditTourForms/EditFileForm/EditFileForm";
import EditDayForm from "../../components/EditTourForms/EditDayForm/EditDayForm";
import ModalComponent from "../../components/UI/Modal/Modal";
import CreateTourPrice from "../../components/CreateTourForms/CreateTourPrice/CreateTourPrice";
import moment from "moment";

const formatDate = date => {
    let d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

class EditTour extends Component {
    day = {
        date: '',
        location_a: '',
        location_b: '',
        description: '',
        services: [],
        additional_locations: [],
        image: '',
        remarks: ''
    };

    file = {
        file: '',
        tour: parseInt(this.props.match.params.id)
    };

    eurDetail = {
        invoice: 'eur',
        detail: '',
        deal: '',
        sum: ''
    };

    usdDetail = {
        invoice: 'usd',
        detail: '',
        deal: '',
        sum: ''
    };

    state = {
        isOpen: false,
        dates: [],
        tour: {
            description: '',
            title: '',
            tourist: '',
            image: '',
            duration: '',
            price_for: '',
            price_and_pax: {
                currency: "USD",
                data: []
            },
            category: '',
            included: '',
            excel: '',
            new_start_date: '',
        },
        days: [],
        files: [],
        usdDetails: [],
        eurDetails: [],
        eurInvoice: {
            type: 1,
        },
        usdInvoice: {
            type: 2,
        },
    };
    id = '';
    owner = '';
    imageFile = '';
    excelFile = '';

    filesUrls = [];
    filesObjects = [];

    componentDidMount() {
        this.props.getTourMetas();
        this.id = this.props.match.params.id;
        this.props.getTourDetails(this.id).then(() => {
            this.props.getTouristInfo(this.props.tourDetails.tourist);
            const days = this.props.tourDetails.days ? this.props.tourDetails.days : [];
            let dates = [];
            if (this.props.tourDetails.days) {
                for (let day of days) {
                    const date = new Date(day.date);
                    dates.push(date);
                }
            }

            const tourPdf = this.props.tourDetails.pdf;
            this.pdf = tourPdf;
            const files = this.props.tourDetails.files;

            this.filesUrls = files;
            this.filesObjects = files;

            let eurInvoice = this.props.tourDetails.invoice_eur;
            let usdInvoice = this.props.tourDetails.invoice_usd;
            let usdDetails;
            if (this.props.tourDetails.invoice_usd && this.props.tourDetails.invoice_usd.details.length > 0) {
                usdDetails = this.props.tourDetails.invoice_usd.details
            } else {
                usdDetails = [];
            }
            let eurDetails;
            if (this.props.tourDetails.invoice_eur && this.props.tourDetails.invoice_eur.details.length > 0) {
                eurDetails = this.props.tourDetails.invoice_eur.details
            } else {
                eurDetails = [];
            }
            let owner = this.props.tourDetails.owner;
            this.owner = owner;

            delete this.props.tourDetails.pdf;
            delete this.props.tourDetails.files;
            delete this.props.tourDetails.days;
            delete this.props.tourDetails.owner;
            delete this.props.tourDetails.invoice_usd;
            delete this.props.tourDetails.invoice_eur;

            const tourDetails = this.props.tourDetails;
            if (tourDetails.price_and_pax.data && tourDetails.price_and_pax.data.length > 0) {
                let newData = [];
                for (let item of tourDetails.price_and_pax.data) {
                    const object = {};
                    object.pax = Object.keys(item)[0];
                    object.price = item[Object.keys(item)];
                    newData.push(object);
                }
                tourDetails.price_and_pax.data = newData;
            } else {
                tourDetails.price_and_pax.currency = 'USD';
                tourDetails.price_and_pax.data = [];
            }
            this.setState({
                days: days,
                tour: tourDetails,
                usdInvoice: usdInvoice,
                eurInvoice: eurInvoice,
                files: files,
                usdDetails: usdDetails,
                eurDetails: eurDetails,
                dates: dates
            });
        });
    }

    isCanEditDays = () => {
        const user = JSON.parse(localStorage.getItem('role'));
        const is_admin = JSON.parse(localStorage.getItem('state')).usr.user.is_admin;
        let canNotEdit;

        if ((user.id === this.owner) || (is_admin === true)) {
            if (this.props.tourDetails.plan) {
                canNotEdit = true;
                return canNotEdit;
            } else {
                canNotEdit = false;
                return canNotEdit
            }
        } else {
            canNotEdit = true;
            return canNotEdit
        }
    };

    checkRole = () => {
        const user = JSON.parse(localStorage.getItem('role'));
        let canNotEdit;
        const is_admin = JSON.parse(localStorage.getItem('state')).usr.user.is_admin;

        if ((user.id === this.owner) || (is_admin === true)) {
            canNotEdit = false;
            return canNotEdit
        } else {
            canNotEdit = true;
            return canNotEdit
        }
    };

    toggle = () => {
        this.props.closeModal()
    };

    ///// INVOICES HANDLERS

    usdChangeHandler = event => {
        this.setState({
            usdInvoice: {...this.state.usdInvoice, [event.target.name]: event.target.value},
        })
    };
    eurChangeHandler = event => {
        this.setState({eurInvoice: {...this.state.eurInvoice, [event.target.name]: event.target.value}})
    };

    addUsdDetail = () => {
        this.setState({
            usdDetails: this.state.usdDetails.concat(this.usdDetail)
        })
    };
    addEurDetail = () => {
        this.setState({
            eurDetails: this.state.eurDetails.concat(this.eurDetail)
        })
    };

    deleteEurDetail = index => {
        const details = this.state.eurDetails;
        details.splice(index, 1);
        this.setState({
            eurDetails: details
        })
    };

    deleteUsdDetail = index => {
        const details = this.state.usdDetails;
        details.splice(index, 1);
        this.setState({
            usdDetails: details
        })
    };

    usdDetailChangeHandler = index => e => {
        this.setState({
            usdDetails: update(this.state.usdDetails, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };
    eurDetailChangeHandler = index => e => {
        this.setState({
            eurDetails: update(this.state.eurDetails, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };

    usdCkEditorDetail = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            usdDetails: update(this.state.usdDetails, {[index]: {detail: {$set: data}}})
        })
    };
    eurCkEditorDetail = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            eurDetails: update(this.state.eurDetails, {[index]: {detail: {$set: data}}})
        })
    };

    /////

    /// HANDLERS FOR FILES

    removeExcelInFiles = index => () => {
        this.setState({
            files: update(this.state.files, {[index]: {file: {$set: ''}}})
        })
    };

    deleteFileHandler = (id, index) => {
        this.props.deleteFile(id);
        this.removeFile(index)
    };

    removeFile = index => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.filesObjects.splice(index, 1);
        this.filesUrls.splice(index, 1);
        this.setState({files});
    };


    pdfFileChange = index => event => {
        this.setState({
            files: update(this.state.files, {[index]: {[event.target.name]: {$set: event.target.files[0]}}})
        });
    };

    addFile = () => {
        this.setState({
            files: this.state.files.concat(this.file)
        });
        this.filesObjects = this.filesObjects.concat(this.file);
    };

    /// HANDLERS FOR DAY
    removeImageInDay = index => () => {
        this.setState({
            days: update(this.state.days, {[index]: {image: {$set: ''}}}),
        });
    };

    addNewDayHandler = () => {
        if (this.state.days.length === 0) {
            const day = {...this.day};
            const days = [...this.state.days];
            day.date = new Date();
            days.push(day);
            return this.setState({
                days: days
            })
        }

        if (this.state.days.length === 1) {
            const day = {...this.day};
            const days = [...this.state.days];
            let dayObj = {...days[0]};
            day.date = dayObj.date;
            let tomorrow = moment(day.date).add(1, 'days');

            day.date = tomorrow.toDate();
            day.location_a = dayObj.location_b;
            days.push(day);
            this.setState({
                days: days
            })
        } else if (this.state.days.length > 1) {
            const day = {...this.day};
            const days = [...this.state.days];
            let dayObj = {...days[days.length - 1]};
            day.date = dayObj.date;
            let tomorrow = moment(day.date).add(1, 'days');

            day.date = tomorrow.toDate();
            day.location_a = dayObj.location_b;
            days.push(day);
            this.setState({
                days: days
            })
        }
    };

    dateChangeHandler = index => date => {
        this.setState({
            days: update(this.state.days, {[index]: {date: {$set: date}}}),
        }, () => {
            this.setState({
                dates: this.state.days.map(day => (day.date))
            })
        });
    };

    dayChangeHandler = index => e => {
        this.setState({
            days: update(this.state.days, {[index]: {[e.target.name]: {$set: e.target.value}}})
        })
    };

    ckEditorDayDescription = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            days: update(this.state.days, {[index]: {description: {$set: data}}})
        })
    };

    ckEditorDayRemarks = index => (e) => {
        const data = e.editor.getData();
        this.setState({
            days: update(this.state.days, {[index]: {remarks: {$set: data}}})
        })
    };

    dayFileChange = index => event => {
        this.setState({
            days: update(this.state.days, {[index]: {[event.target.name]: {$set: event.target.files[0]}}})
        })
    };

    clickCheck = index => e => {
        if (this.state.days[index].services.includes(parseInt(e.target.value))) {
            let services = [...this.state.days[index].services];
            services.splice(services.indexOf(parseInt(e.target.value)), 1);
            this.setState({
                days: update(this.state.days, {[index]: {services: {$set: services}}})
            })
        } else {
            this.setState({
                days: update(this.state.days, {[index]: {services: {$set: [...this.state.days[index].services].concat(parseInt(e.target.value))}}})
            })
        }
    };
    handleClickAdditionalLocations = index => e => {
        if (this.state.days[index].additional_locations.includes(parseInt(e.target.value))) {
            let additional_locations = [...this.state.days[index].additional_locations];
            additional_locations.splice(additional_locations.indexOf(parseInt(e.target.value)), 1);
            this.setState({
                days: update(this.state.days, {[index]: {additional_locations: {$set: additional_locations}}})
            })
        } else {
            this.setState({
                days: update(this.state.days, {[index]: {additional_locations: {$set: [...this.state.days[index].additional_locations].concat(parseInt(e.target.value))}}})
            })
        }
    };

    removeDay = (index) => {
        const dates = [...this.state.dates];
        let days = [...this.state.days];
        const day = days[index];
        const date = days[index].date;
        for (let i = 0; i < dates.length; i++) {
            if (dates[i] === date) {
                dates.splice(i, 1);
            }
        }
        let count = index + 1;
        for (let i = count; i < days.length; i++) {
            const day = {...days[i]};
            let nextDay = moment(day.date).subtract(1, 'days');
            days[i].date = nextDay.toDate();
        }
        days.splice(index, 1);
        if (day.id) {
            this.props.deleteDay(day.id);
        }
        this.setState({days, dates})
    };
    /////

    /// HANDLERS FOR TOUR
    clearExcelInTour = () => {
        this.excelFile = '';
        this.setState({
            tour: {...this.state.tour, excel: null}
        })
    };

    clearImage = () => {
        this.imageFile = '';
        this.setState({
            tour: {...this.state.tour, image: null}
        })
    };

    changeHandler = (e) => {
        this.setState({tour: {...this.state.tour, [e.target.name]: e.target.value}});
    };

    fileChangeHandler = event => {
        this.setState({tour: {...this.state.tour, [event.target.name]: event.target.files[0]}});
    };

    ckeditorChangeHandler = (e) => {
        const data = e.editor.getData();
        this.setState({tour: {...this.state.tour, description: data}})
    };

    imageTourChange = event => {
        this.imageFile = event.target.files[0];
        this.setState({
            tour: {...this.state.tour, image: event.target.files[0]}
        })
    };

    excelFileTour = event => {
        this.excelFile = event.target.files[0];
        this.setState({
            tour: {...this.state.tour, excel: event.target.files[0]}
        })
    };

    addPriceAndPaxHandler = () => {
        const object = {
            pax: '',
            price: ''
        };
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: this.state.tour.price_and_pax.data.concat(object)
                    }
            }
        })
    };

    changeCurrencyHandler = currency => {
        this.setState({
            tour: {
                ...this.state.tour,
                price_and_pax: {
                    ...this.state.tour.price_and_pax,
                    currency: currency
                }
            }
        })
    };

    changePriceHandler = index => event => {
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: update(this.state.tour.price_and_pax.data, {[index]: {[event.target.name]: {$set: event.target.value}}})
                    }
            }
        });
    };

    deletePriceHandler = item => {
        let data = this.state.tour.price_and_pax.data;
        const index = data.findIndex(element => element === item);
        data.splice(index, 1);
        this.setState({
            tour: {
                ...this.state.tour,
                price_and_pax: {...this.state.tour.price_and_pax, data: data}
            }
        })
    };

    changePaxHandler = index => (event) => {
        this.setState({
            tour: {
                ...this.state.tour, price_and_pax:
                    {
                        ...this.state.tour.price_and_pax,
                        data: update(this.state.tour.price_and_pax.data, {[index]: {[event.target.name]: {$set: event.target.value}}})
                    }
            }
        });
    };

    addTourIncludedCk = e => {
        const data = e.editor.getData();
        this.setState({tour: {...this.state.tour, included: data}})
    };

    /////

    //// FUNCTIONS SUBMIT

    submitFormHandler = event => {
        event.preventDefault();
        const tour = this.state.tour;
        const days = this.state.days;
        const files = this.state.files;
        const eurInvoice = this.state.eurInvoice;
        const usdInvoice = this.state.usdInvoice;
        const usdDetails = this.state.usdDetails;
        const eurDetails = this.state.eurDetails;

        const data = tour.price_and_pax.data;
        let newData = [];
        for (let item of data) {
            let object = {};
            object[item.pax] = item.price;
            newData.push(object);
        }
        tour.price_and_pax.data = newData;

        tour.price_and_pax = JSON.stringify(tour.price_and_pax);
        delete tour.pdf_preliminary;

        const formDataTour = new FormData();
        Object.keys(tour).forEach(key => {
            if (key === 'group_size') {
                formDataTour.append(key, parseInt(tour[key]));
            } else if (tour[key] === null) {
                tour[key] = '';
                formDataTour.append(key, tour[key]);
            } else if (key === 'image' && tour[key].length > 0) {
                if (typeof tour[key] !== 'object') {
                    delete tour[key]
                } else {
                    formDataTour.append(key, tour[key]);
                }
            } else if (key === 'excel' && tour[key].length > 0) {
                if (typeof tour[key] !== 'object') {
                    delete tour[key]
                } else {
                    formDataTour.append(key, tour[key]);
                }
            } else {
                formDataTour.append(key, tour[key]);
            }
        });
        if (tour.new_start_date !== '' &&  tour.new_start_date !== undefined ) {
            formDataTour.set('new_start_date', formatDate(tour.new_start_date))
        }

        const tourData = {tour: formDataTour, days, files, eurInvoice, usdInvoice, eurDetails, usdDetails};
        this.props.updateTour(this.id, tourData, window.location);
    };

    saveTemplate = () => {
        const data = this.state.tour;
        this.props.saveAsTemplate(data);
    };

    createTravelPlanHandler = async () => {
        if (this.props.tourDetails.plan !== null) {
            this.props.history.push(`/travel_plan/${this.props.tourDetails.plan}`);
        } else {
            try {
                const response = await axios.post('/api/v1/plans/', {tour: this.id});
                this.props.history.push(`/travel_plan/${response.data.id}`);
            } catch (error) {
                if (error.response.status === 400) {
                    NotificationManager.error("Error", error.response.data.detail)
                } else {
                    NotificationManager.error("Error", "Something went wrong!")
                }
            }

        }
    };

    /////

    showData = (type) => {
        if(type === 'eurInvoice') {
            const win = window.open(this.state.eurInvoice.pdf, '_blank');
            win.focus();
        } else if(type === 'tour_pdf') {
            const win = window.open(this.pdf, '_blank');
            win.focus();
        } else if(type === 'usdInvoice') {
            const win = window.open(this.state.usdInvoice.pdf, '_blank');
            win.focus();
        } else if(type === 'img_preliminary') {
            const win = window.open(this.state.tour.img_preliminary, '_blank');
            win.focus();
        } else if(type === 'img') {
            const win = window.open(this.state.tour.img, '_blank');
            win.focus();
        }
    };

    render() {
        const isAdmin = JSON.parse(localStorage.getItem("state")).usr.user.is_admin;
        return (
            this.props.tourDetails && !this.props.isLoading ? <Fragment>
                <Row>
                    <Col className="col-8 offset-sm-2">
                        {this.props.touristData &&
                        <h1 className="page_title">Edit tour for <NavLink style={{textDecoration: 'none'}}
                                                                          to={`/tourist-info/${this.props.touristData.id}`}>
                            {this.props.touristData.full_name}
                        </NavLink>
                        </h1>}

                        <div className="line_helper"></div>

                        <div className="add_form_tour_wrapper">
                            <Form onSubmit={this.submitFormHandler}>
                                <div className="tour_part_1_wrapper">
                                    <EditFormTour
                                        excelFileTour={this.excelFileTour}
                                        imageTourChange={this.imageTourChange}
                                        disabled={this.checkRole()}
                                        clearExcelInTour={this.clearExcelInTour}
                                        clearImage={this.clearImage}
                                        myRef={this.myRef}
                                        tour={this.state.tour}
                                        tourMetas={this.props.tourMetas}
                                        imageChange={this.imageChange}
                                        changeHandler={this.changeHandler}
                                        fileChange={this.fileChangeHandler}
                                        ckEditor={this.ckeditorChangeHandler}
                                        submitHandler={this.submitFormHandler}
                                    />
                                </div>


                                <div className="line_helper"></div>

                                {this.props.tourDetails.plan ? <Alert color="warning">
                                    You can not edit dates of days because travel plan already created!
                                </Alert> : null}

                                <div className="days_part_wrapper">
                                    <h1 className="page_title">Edit days</h1>
                                    {this.state.days && this.state.days.map((day, index) => {
                                        return (
                                            <EditDayForm
                                                dayNum={index + 1}
                                                countDays={this.countDays + 1}
                                                disabled={this.checkRole()}
                                                isCanEditDays={this.isCanEditDays()}
                                                removeImageInDay={this.removeImageInDay(index)}
                                                dates={this.state.dates}
                                                removeDay={() => this.removeDay(index)}
                                                key={index}
                                                setDate={this.dateChangeHandler(index)}
                                                handleClick={this.clickCheck(index)}
                                                handleClickAdditionalLocations={this.handleClickAdditionalLocations(index)}
                                                day={day}
                                                dayFileChange={this.dayFileChange(index)}
                                                ckEditorDayDescription={this.ckEditorDayDescription(index)}
                                                ckEditorDayRemarks={this.ckEditorDayRemarks(index)}
                                                dayChange={this.dayChangeHandler(index)}
                                                tourMetas={this.props.tourMetas}
                                            />
                                        )
                                    })}
                                    {!this.isCanEditDays() ? <FormGroup>
                                        <Button color='primary' onClick={this.addNewDayHandler}>Add another day</Button>
                                    </FormGroup> : null}
                                </div>


                                <div className="line_helper"></div>

                                <div className="files_part_wrapper">
                                    <h1 className="page_title">Edit files</h1>
                                    {this.state.files.map((file, index) => {
                                        return (
                                            <EditFileForm
                                                disabled={this.checkRole()}
                                                removeFileInFiles={this.removeExcelInFiles(index)}
                                                deleteFile={() => this.deleteFileHandler(file.id, index)}
                                                file={file}
                                                key={index}
                                                index={index}
                                                filesUrls={this.filesUrls}
                                                fileChange={this.pdfFileChange(index)}
                                            />
                                        )
                                    })}

                                    {!this.checkRole() ? <FormGroup>
                                        <Button color='primary' onClick={this.addFile}>Add another file</Button>
                                    </FormGroup> : null}
                                </div>


                                <div className="line_helper"></div>
                                <div className="price_part_wrapper">
                                    <CreateTourPrice
                                        changePaxHandler={this.changePaxHandler}
                                        changeCurrencyHandler={this.changeCurrencyHandler}
                                        deletePrice={this.deletePriceHandler}
                                        changePriceHandler={this.changePriceHandler}
                                        data={this.state.tour.price_and_pax}
                                        addPriceAndPaxHandler={this.addPriceAndPaxHandler}
                                        tour={this.state.tour}
                                        addTourIncludedCk={this.addTourIncludedCk}
                                    />
                                </div>


                                <div className="line_helper"></div>

                                <div className="invoice_part_wrapper">
                                    <h1 className="page_title">Edit invoices</h1>
                                    <InvoicesTabs
                                        deleteUsdDetail={(index) => this.deleteUsdDetail(index)}
                                        deleteEurDetail={(index) => this.deleteEurDetail(index)}
                                        disabled={this.checkRole()}
                                        usdChangeHandler={this.usdChangeHandler}
                                        eurChangeHandler={this.eurChangeHandler}
                                        usdDetailChangeHandler={this.usdDetailChangeHandler}
                                        eurDetailChangeHandler={this.eurDetailChangeHandler}
                                        usdDetails={this.state.usdDetails}
                                        eurDetails={this.state.eurDetails}
                                        usdCkEditorDetail={this.usdCkEditorDetail}
                                        eurCkEditorDetail={this.eurCkEditorDetail}
                                        addEurDetail={this.addEurDetail}
                                        addUsdDetail={this.addUsdDetail}
                                    />
                                </div>


                                {!this.checkRole() ? <Fragment>
                                    <div className="line_helper"></div>
                                    <Row className="tour_edit_buttons_row">
                                        <Col>
                                            <FormGroup>
                                                <Button type='submit' color='success'>Save tour</Button>
                                            </FormGroup>
                                        </Col>
                                        {!this.checkRole() ?
                                            <Col className="text-center">
                                                {this.state.days.length > 0 ?
                                                    <Button onClick={this.createTravelPlanHandler}
                                                            className="edit_travel_plan_button">
                                                        {this.props.tourDetails.plan ? "Edit travel plan" : "Create Travel Plan"}
                                                    </Button>
                                                    : null}
                                            </Col> :
                                            null}

                                        {isAdmin ? <Col className="text-right">
                                            <Button
                                                color='primary'
                                                onClick={this.saveTemplate}
                                                disabled={this.props.disabled}
                                            >
                                                Save as template
                                            </Button>
                                        </Col> : null}
                                    </Row>

                                </Fragment> : null}
                            </Form>
                        </div>
                    </Col>
                </Row>
                <div className="col-sm-10 offset-sm-1">

                    <Row className="tour_edit_buttons_row">
                        <div className="line_helper"></div>
                        <Col className="text-center">
                            <div className="wrapper">
                                <Button onClick={() => this.showData('tour_pdf')} disabled={!this.pdf} className='btn btn-secondary'>
                                    PDF
                                </Button>
                            </div>

                            <div className="wrapper">
                                {this.state.tour &&
                                <a className='pdf_link' href={this.state.tour.pdf_preliminary} target='_blank'>
                                    <Button disabled={!this.state.tour.pdf_preliminary}>
                                        PDF 2
                                    </Button>
                                </a>
                                }
                            </div>
                        </Col>
                        {this.state.tour && <Col className="text-center">
                            <div className="wrapper">
                                <Button className='btn btn-secondary' onClick={() => this.showData('img')} disabled={!this.state.tour.img}>
                                    IMG
                                </Button>
                            </div>
                            <div className="wrapper">
                                <Button className='btn btn-secondary' onClick={() => this.showData('img_preliminary')}
                                        disabled={!this.state.tour.img_preliminary}>
                                    IMG 2
                                </Button>
                            </div>
                        </Col>}

                        <Col className="text-center">
                            <div className="wrapper">
                                {this.state.eurInvoice ?
                                    <Button onClick={() => this.showData('eurInvoice')} className="btn btn-secondary" disabled={!this.state.eurInvoice.pdf}>
                                        EUR
                                    </Button>
                                    : null}
                            </div>
                            <div className="wrapper">
                                {this.state.usdInvoice ?
                                    <Button onClick={() => this.showData('usdInvoice')} className="btn btn-secondary" disabled={!this.state.usdInvoice.pdf}>
                                        USD
                                    </Button>
                                    : null}
                            </div>
                        </Col>

                    </Row>
                </div>


                <ModalComponent
                    toggleModal={this.toggle}
                    isOpen={this.props.isOpen}
                    saved={'Template saved!'}
                />

            </Fragment> : <Preloader/>
        );
    }
}

const mapStateToProps = state => ({
    tourMetas: state.tours.tourMetas,
    touristData: state.tr.touristInfo,
    isLoading: state.tr.isLoading,
    tourDetails: state.tours.tourDetails,
    isOpen: state.templates.isOpen,
    disabled: state.templates.disabledTempBtn,
    plan: state.plan.plan
});

const mapDispatchToProps = dispatch => ({
    getTourMetas: () => dispatch(getTourMetas()),
    getTourDetails: (id) => dispatch(getTourDetail(id)),
    getTouristInfo: (id) => dispatch(getTouristInfo(id)),
    updateTour: (id, tourData, location) => dispatch(updateTour(id, tourData, location)),
    deleteFile: (id) => dispatch(deleteFile(id)),
    saveAsTemplate: (data) => dispatch(saveAsTemplate(data)),
    closeModal: () => dispatch(closeModal()),
    deleteDay: id => dispatch(deleteDay(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTour);
