import React from 'react';
import {FormGroup, Label} from 'reactstrap';
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css"

const DatePickerComponent = (props) => {
    return (
        <FormGroup>
            <Label>Date</Label>
            <DatePicker
                disabled={props.disabled}
                dateFormat="E MMMM d, yyyy"
                excludeDates={props.dates}
                selected={props.selected}
                onChange={(date) => props.setDate(date)}
                className="form-control"
            />
        </FormGroup>
    );
};

export default DatePickerComponent;
