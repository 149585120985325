import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';

import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REFRESH_TOKEN_SUCCESS,
    LOGOUT_USER,
    LOGIN_USER_REQUEST,
    DESTROY_SESSION
} from "./actionTypes";

export const destroySession = () => {
    return {type: DESTROY_SESSION}
};

export const loginUserRequest = () => {
    return {type: LOGIN_USER_REQUEST}
};

const loginUserSuccess = data => {
    return {type: LOGIN_USER_SUCCESS, data};
};

const loginUserError = error => {
    return {type: LOGIN_USER_ERROR, error};
};

export const refreshTokenSuccess = token => ({type: REFRESH_TOKEN_SUCCESS, token});

export const loginUser = (userData, history) => {
    return async dispatch => {
        dispatch(loginUserRequest());
        try {
            const response = await axios.post('/api/v1/token/', userData);
            dispatch(loginUserRequest());
            dispatch(loginUserSuccess(response.data));
            history.push('/');
        } catch (e) {
            dispatch(loginUserRequest());
            dispatch(loginUserError(e));
            NotificationManager.error("Error", "Login error");
        }
    }
};

export const logoutUser = () => {
    return async (dispatch, getState) => {
        dispatch(loginUserRequest());
        try {
            const data = {refresh: getState().usr.user.refresh};
            await axios.post('/api/v1/token/blacklist/', data);
            dispatch({type: LOGOUT_USER});
            localStorage.removeItem('role');
            dispatch(loginUserRequest());
            window.location.href = process.env.REACT_APP_BASEURL;
        } catch (e) {
            dispatch(loginUserRequest());
            dispatch(loginUserError(e));
            NotificationManager.error("Error", "Logout error");
        }
    }
};
