import React, {Fragment} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from 'reactstrap';

const ModalComponent = props => {
    return (
        <Fragment>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
                <ModalHeader toggle={props.toggleModal}>Saving template</ModalHeader>
                <ModalBody>
                    <p>{props.saved}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={props.toggleModal}>Close</Button>{' '}
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default ModalComponent;
