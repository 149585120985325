import React, {Fragment} from 'react';
import {NotificationContainer} from "react-notifications";
import Header from "../UI/Header/Header";
import Footer from "../UI/Footer/Footer";
import {logoutUser} from "../../store/actions/userActions";
import {connect} from "react-redux";

const ListLayout = (props) => {
    return (
        <Fragment>
            <NotificationContainer/>
            <Header logout={props.logoutUser} user={props.user}/>
            <div className='container-fluid'>
                {props.children}
            </div>
            <Footer/>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.usr.user
});

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(ListLayout);
