import {GET_PLANS_HISTORY_SUCCESS} from "../actions/actionTypes";

const initialState = {
    planHistory: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANS_HISTORY_SUCCESS:
            return {...state, planHistory: action.data};
        default:
            return state
    }
};

export default reducer;
