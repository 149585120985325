import {GET_PLANS_HISTORY_SUCCESS} from "./actionTypes";
import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';

const getPlansHistorySuccess = data => ({type: GET_PLANS_HISTORY_SUCCESS, data});

// export const getPlansHistory = id => {
//     return async dispatch => {
//         try {
//             const response = await axios.get(`/api/v1/history/${id}/`);
//             dispatch(getPlansHistorySuccess(response.data))
//         } catch (error) {
//             NotificationManager.error('Error', 'Can not get plans actions!!')
//         }
//     }
// };

export const getPlansHistory = id => {
    return dispatch => {
        return axios.get(`/api/v1/history/${id}/`).then(response => {
            dispatch(getPlansHistorySuccess(response.data))
        }, error => {
            NotificationManager.error("Error", 'Can not get plans history')
        })
    }
};
