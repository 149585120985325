import thunkMiddleware from "redux-thunk";
import {applyMiddleware, createStore, combineReducers, compose} from "redux";

import usersReducer from "./reducers/usersReducer";
import touristsReducer from './reducers/touristsReducer';
import tourReducer from './reducers/tourReducer';
import templatesReducer from './reducers/templatesReducer';
import planReducer from './reducers/planReducer';
import planHistory from './reducers/plansHistoryReducer';
import driversDashboard from './reducers/driversDashboardReducer';

import {saveState, loadState} from "./localStorage";


const rootReducer = combineReducers({
    usr: usersReducer,
    tr: touristsReducer,
    tours: tourReducer,
    templates: templatesReducer,
    plan: planReducer,
    planHistory: planHistory,
    driversDashboard: driversDashboard
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(applyMiddleware(thunkMiddleware));
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
    saveState({
        usr: store.getState().usr
    });
});

export default store;
