import React, {Fragment} from 'react';
import {Row, Col, Input, Label, Button} from 'reactstrap';

import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";

const editorConfig = customConst.editorConfig;

const InvoiceDetailUsdForm = props => {
    return (
        <Fragment>
            <Row>
                <Col>
                    <Label for='usd_detail'>Detail *</Label>
                    <CKEditor
                        idd='usd_detail'
                        name='detail'
                        type={editorConfig.type}
                        readOnly={props.disabled}
                        data={props.detail === null ? '' : props.detail}
                        config={editorConfig}
                        onChange={props.usdCkEditorDetail}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Label>Deal</Label>
                    <Input
                        disabled={props.disabled}
                        value={props.deal}
                        type='text'
                        name='deal'
                        onChange={props.usdDetailChangeHandler}/>
                </Col>

                <Col>
                    <Label>Sum *</Label>
                    <Input
                        disabled={props.disabled}
                        required
                        value={props.sum}
                        type='number'
                        name='sum'
                        onChange={props.usdDetailChangeHandler}
                    />
                </Col>
            </Row>
            {!props.disabled ? <Row>
                <Col>
                    <Button color='danger' onClick={props.deleteUsdDetail} className="mt30">Delete detail</Button>
                </Col>
            </Row> : null}
        </Fragment>
    );
};

export default InvoiceDetailUsdForm;
