import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {loginUser} from "../../store/actions/userActions";
import {Redirect} from 'react-router-dom';
import {Row, Col, Label, Input, FormGroup, Form, Button} from 'reactstrap';

const fields = [
    {type: 'text', id: 'email', name: 'email', label: 'Email', required: true},
    {type: 'password', id: 'password', name: 'password', label: 'Password', required: true}
];

class LoginPage extends Component {

    state = {
        email: '',
        password: ''
    };

    inputChangeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    submitHandler = e => {
        e.preventDefault();
        this.props.loginUser(this.state, this.props.history);
    };

    render() {
        if (this.props.user) {
            return <Redirect to='/tourists'/>
        } else {
            return (
                <Fragment>
                    <Row className="col-sm-6">
                        <Col>
                            <Form onSubmit={this.submitHandler}>
                                {fields.map((field, index) => {
                                    return (
                                        <FormGroup key={index}>
                                            <Label for={field.id}>{field.label}{field.required ? ' * ' : ''}</Label>
                                            <Input
                                                required
                                                id={field.id}
                                                type={field.type}
                                                value={this.state[field.name]}
                                                name={field.name}
                                                onChange={this.inputChangeHandler}
                                            />
                                        </FormGroup>
                                    )
                                })}
                                <FormGroup>
                                    <Button color='primary' type='submit'>Login</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Fragment>
            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.usr.user
});

const mapDispatchToProps = dispatch => ({
    loginUser: (userData, history) => dispatch(loginUser(userData, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
