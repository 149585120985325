import React, {Fragment} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const OrderModal = props => {
    let div = document.createElement("div");
    div.innerHTML = props.order.mail;
    const text = div.innerText;

    let div2 = document.createElement("div");
    div2.innerHTML = props.order.last_email_mail;
    const text2 = div.innerText;
    return (
        <Fragment>
            <Modal isOpen={props.isOpen} toggle={() => props.modalHandler()}>
                <ModalHeader>Order's info</ModalHeader>
                <ModalBody>
                    <p><b>Updated by :</b> {props.order.user}</p>

                    <p><b>Start date:</b> {props.order.start_date}</p>
                    <p><b>End date:</b> {props.order.end_date}</p>

                    <p><b>Status:</b> {props.order.status_text}</p>

                    <p><b>Last SMS:</b> {!props.order.last_sms ? 'No sms' : props.order.last_sms}</p>
                    <p><b>Last Email:</b> {!props.order.last_email ? 'No email' : props.order.last_email}</p>
                    <p><b>Last SMS Text:</b> {!props.order.last_sms ? 'No sms' : props.order.last_sms_text}</p>
                    <p><b>Last Email Text:</b> {!props.order.last_email ? 'No email' : text2}</p>

                    <p><b>Mail:</b> {text}</p>
                    <p><b>Sms:</b> {props.order.sms}</p>
                    <p><b>Note:</b> {props.order.note}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => props.modalHandler()}>Close</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default OrderModal;
