import React, {Fragment} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from 'reactstrap';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css"

import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import ServiceProvidersModal from "../../UI/ServiceProvidersModal/ServiceProvidersModal";
import {renderOrders} from "../ProvidersModalForms/renderFunctions";

const DayComponent = props => {
    let isDisabled = false;
    const user = props.user;
    const plan = props.plan;

    if (user.is_admin === false) {
        if (user.user !== plan.owner) {
            isDisabled = true
        } else {
            isDisabled = false
        }
    }

    return (
        <Fragment>
            {props.days.map((day, index) => {
                return (
                    <Fragment key={day.id}>
                        <Card style={{marginTop: '15px'}} className="travel_plan_day">
                            <CardBody>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <CardTitle>
                                            <h3 className="day_title">
                                                <b>Day {index + 1}</b>
                                                <TooltipComponent
                                                    description={day.description}
                                                    id={`tooltip_day_${index + 1}`}
                                                />
                                                <span className="date">{new Date(day.date).toDateString()}</span>
                                            </h3>
                                        </CardTitle>
                                        <div dangerouslySetInnerHTML={{__html: day.remarks}}></div>
                                        <p>
                                            <b>Location A -</b> {day.location_a.title}<br/>
                                            <b>Location B -</b> {day.location_b.title}
                                        </p>
                                        { !day.additional_locations_represent.length > 0 ? null :
                                        <p>
                                            <b>Additional locations: </b> {day.additional_locations_represent.map(location => {
                                                return (<span>{location.title}, </span>)
                                        })}
                                        </p>
                                        }

                                        <h5><i>Services for this day</i></h5>
                                        <Row>
                                            <Col>
                                                {day.services.map((service, index) => {

                                                    if (day.services[index + 1]) {
                                                        return (
                                                            <span key={index}>{service.title}, </span>
                                                        )
                                                    } else {
                                                        return (
                                                            <span key={index}>{service.title}</span>
                                                        )
                                                    }

                                                })}
                                            </Col>
                                        </Row>

                                        <br/>
                                    </div>

                                    <div className="col-sm-6">
                                        {props.orders && props.orders.map((item) => {
                                            const items = [];
                                            if (item.day === day.id) {
                                                items.push(...renderOrders(item, 'driver', 'driver_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'extra', 'extra_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'guide', 'guide_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'home_state', 'home_state_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'guest_house', 'guest_house_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'hotel', 'hotel_orders', props.openEditModal));
                                                items.push(...renderOrders(item, 'yurt_camp', 'yurt_camp_orders', props.openEditModal))
                                            }
                                            return items;
                                        })}
                                    </div>

                                </div>
                                {!day.description ? null :
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h5>Description</h5>
                                            <div dangerouslySetInnerHTML={{__html: day.description}}></div>
                                        </div>
                                    </div>
                                }

                                {isDisabled ? null : <div className="row">
                                    <div className="col-sm-12">
                                        <span className='add_provider' onClick={() => props.toggle(day)}
                                              id={`add_provider_button_${index + 1}`}>
                                                <FontAwesomeIcon icon={faPlus}/>
                                                <span className="title">Add provider</span>
                                        </span>
                                    </div>
                                </div>}

                            </CardBody>
                        </Card>
                    </Fragment>
                )
            })}

            <ServiceProvidersModal
                modalHandler={props.modalHandler}
                providers={props.providers}
                toggle={props.toggle}
                modal={props.modal}
            />
        </Fragment>
    );
};

export default DayComponent;
