import {
    CREATE_TRAVEL_PLAN_SUCCESS,
    GET_PLANS_LIST_SUCCESS,
    GET_PLAN_DETAIL_SUCCESS,
    GET_PLAN_DETAIL_ERROR,
    GET_PLAN_DETAIL_REQUEST,
    CREATE_ORDER_SUCCESS
} from '../actions/actionTypes';

const initialState = {
    plansList: null,
    planDetail: null,
    plan: null,
    error: null,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_PLANS_LIST_SUCCESS:
            return {...state, plansList: action.data};
        case GET_PLAN_DETAIL_SUCCESS:
            return {...state, planDetail: action.data, orders: null};
        case GET_PLAN_DETAIL_REQUEST:
            return {...state, isLoading: !state.isLoading};
        case GET_PLAN_DETAIL_ERROR:
            return {...state, error: action.error};
        case CREATE_TRAVEL_PLAN_SUCCESS:
            return {...state, plan: action.data};
        case CREATE_ORDER_SUCCESS:
            return {...state, planDetail: {...state.planDetail, orders: action.data}};
        default:
            return state
    }
};

export default reducer;
