import {
    GET_PLAN_DETAIL_SUCCESS,
    GET_PLAN_DETAIL_ERROR,
    GET_PLANS_LIST_SUCCESS,
    CREATE_ORDER_SUCCESS,
} from './actionTypes';
import axios from '../../axios-api';
import axiosTest from 'axios';
import {NotificationManager} from 'react-notifications';

const getPlanDetailSuccess = data => ({type: GET_PLAN_DETAIL_SUCCESS, data});
const getPlanDetailError = error => ({type: GET_PLAN_DETAIL_ERROR});
const FileDownload = require('js-file-download');

export const getPlanDetail = id => {
    return async dispatch => {
        try {
            const response = await axios.get(`/api/v1/plans/${id}/`);
            dispatch(getPlanDetailSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    }
};

const getPlansListSuccess = data => ({type: GET_PLANS_LIST_SUCCESS, data});

export const getPlans = (dates) => {
    return async dispatch => {
        if(dates) {
            try {
                // const response = await axiosTest.get('http://localhost:3000/data.json');
                const response = await axios.get(`/api/v1/dashboard/plans/?start_date=${dates.start_date}&end_date=${dates.end_date}`);
                dispatch(getPlansListSuccess(response.data));
            } catch (e) {
                NotificationManager.error('Error', "Can not get plans!")
            }
        } else {
            try {
                // const response = await axiosTest.get('http://localhost:3000/data.json');
                const response = await axios.get(`/api/v1/dashboard/plans/`);
                dispatch(getPlansListSuccess(response.data));
            } catch (e) {
                NotificationManager.error('Error', "Can not get plans!")
            }
        }
    }
};


const createOrderSuccess = data => ({type: CREATE_ORDER_SUCCESS, data});

export const createDriverOrder = (data) => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/driver/', data);
            const orders = response.data.orders;
            dispatch(createOrderSuccess(orders))
        } catch (error) {
            if (error.response.status === 400 || error.response.status === 404) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateDriverOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/driver/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders))
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const saveTravelPlan = (data, id) => {
    return async dispatch => {
        try {
            await axios.put(`/api/v1/plans/${id}/`, data);
            dispatch(getPlanDetail(id));
            NotificationManager.success('Success', "Plan saved successfully")
        } catch (error) {
            if (error.response.status === 400 || error.response.status === 404) {
                NotificationManager.error("Error", error.response.data.detail)
            }
        }
    }
};

export const createExtraOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/extra/', data);
            dispatch(createOrderSuccess(response.data.orders))
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateExtraOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/extra/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders))
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const createGuideOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/guide/', data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateGuideOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/guide/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const createHomeStateOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post( '/api/v1/orders/home_state/', data);
            dispatch(createOrderSuccess(response.data.orders))
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateHomeStateOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/home_state/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders))
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const createGuestHouseOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/guest_house/', data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateGuestHouseOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/guest_house/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const createHotelOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/hotel/', data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateHotelOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/hotel/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const createYurtOrder = data => {
    return async dispatch => {
        try {
            const response = await axios.post('/api/v1/orders/yurt_camp/', data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const updateYurtOrder = (data, id) => {
    return async dispatch => {
        try {
            const response = await axios.put(`/api/v1/orders/yurt_camp/${id}/`, data);
            dispatch(createOrderSuccess(response.data.orders));
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        }
    }
};

export const cancelTravelPlan = (id) => {
    return dispatch => {
        return axios.post(`/api/v1/plans/${id}/cancel`).then(response => {
        }, error => {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        })
    }
};

export const getPlanReport = (id) => {
    return dispatch => {
        return axios.get(`/api/v1/plans/${id}/report`, {responseType: 'blob'}).then(response => {
            FileDownload(response.data, 'report.xlsx');
        }, error => {
            if (error.response.status === 400) {
                NotificationManager.error("Error", error.response.data.detail)
            } else {
                NotificationManager.error("Error", "Something went wrong!")
            }
        })
    }
};
