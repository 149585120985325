import React, {Fragment} from 'react';
import {FormGroup, Row, Col, Label, Input, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";

const editorConfig = customConst.editorConfig;

const myImageRef = React.createRef();
const myExcelRef = React.createRef();

const AddFormTour = (props) => {
    const clearImage = () => {
        myImageRef.current.value = null;

        props.clearImage()
    };
    const clearExcel = () => {
        myExcelRef.current.value = null;
        props.clearExcelInTour()
    };

    return (
        props.tourMetas ? <Fragment>
            <div className="add_form_tour_wrapper">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='category'>Categories *</Label>
                            <Input
                                required
                                type="select"
                                name="category"
                                id="category"
                                onChange={props.changeHandler}
                                value={props.tour.category}
                            >
                                <option value="">Choose category</option>
                                {props.tourMetas.categories.map(category => {
                                    return (
                                        <option
                                            key={category.id}
                                            value={category.id}
                                        >{category.title}</option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label>Duration *</Label>
                            <Input
                                type='select'
                                name='duration'
                                onChange={props.changeHandler}
                                value={props.tour.duration}
                            >
                                <option>Choose duration</option>
                                {props.tourMetas.durations.map(duration => {
                                    return (
                                        <option
                                            key={duration.id}
                                            value={duration.id}
                                        >{duration.title}</option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label>Tour title *</Label>
                    <Input
                        required
                        type='text'
                        name='title'
                        onChange={props.changeHandler}
                        value={props.tour.title}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for='create_tour_description'>Description</Label>
                    <CKEditor
                        id='create_tour_description'
                        data={props.tour.description === null ? '' : props.tour.description}
                        config={editorConfig}
                        onChange={props.ckEditor}
                        name='description'
                        type={editorConfig.type}
                    />
                </FormGroup>

                <Row>

                    {props.imageUrl !== '' ?
                        <Fragment>
                            {props.imageUrl !== null ?
                                <Col>
                                    <Label>Here is chosen image. You can change it if you want.</Label>
                                    <Alert>
                                        <a href={props.imageUrl} target='bzlank'>Image</a>
                                    </Alert>
                                </Col>
                                :
                                null
                            }

                            <Col>
                                <FormGroup>
                                    <Label for='image'>Image</Label>
                                    <div className='tour_attachment_wrapper'>
                                        <Input id='image'
                                               type='file'
                                               name='image'
                                               onChange={props.imageChange}
                                               innerRef={myImageRef}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Fragment>
                        :
                        <Col>
                            <FormGroup>
                                <Label for='image'>Image</Label>
                                <Input id='image'
                                       type='file'
                                       name='image'
                                       onChange={props.imageChange}
                                       innerRef={myImageRef}
                                />
                            </FormGroup>
                        </Col>
                    }
                </Row>

                <Row>
                    <Col className="d-none">
                        <FormGroup>
                            <Label>Price for *</Label>
                            <Input onChange={props.changeHandler} type='select' name='price_for' value={props.tour.price_for}>
                                <option>Choose type</option>
                                <option value='1'>For person</option>
                                <option value='2'>For group</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Excel File</Label>
                            <Input type='file'
                                   name='excel'
                                   onChange={props.fileChange}
                                   id='excel'
                                   innerRef={myExcelRef}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </Fragment> : null
    );
};

export default AddFormTour;
