import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getPlans} from "../../store/actions/planActions";
import {Col, FormGroup, Button, Label, ListGroup, ListGroupItem, Row, Tooltip, Alert} from 'reactstrap';
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css';
import TableComponent from "../TableComponent/TableComponent";
import history from "../../history";
import {Redirect} from "react-router";

const $ = require('jquery');
$.DataTable = require('datatables.net-fixedcolumns-bs4')

class PlansList extends Component {

    state = {
        dates: [],
        plans: [],
        months: [],
        sorted: {},
        start_date: '',
        end_date: '',
        isOpen: false,
        cash: '',
        cashless: '',
        estimate: '',
        isLoad: false
    };

    dataTable = null;

    async componentDidMount() {
        if(!this.props.user) {
            return
        }
        if(this.props.location.search !== '') {
            const start_date = this.props.location.search.slice(12, -20);
            const end_date = this.props.location.search.slice(32);
            const dates = {
                start_date: start_date,
                end_date: end_date
            };
            await this.props.getPlans(dates);
        } else {
            await this.props.getPlans();
        }

        this.setState({
            isLoad: true,
            start_date: new Date(this.props.plans.start_date),
            end_date: new Date(this.props.plans.end_date)
        });
        this.sortArray();
    };

    sortArray = () => {
        if (!this.props.plans.dates) {
            this.setState({
                sorted: {}
            });
            return
        }
        const array = this.props.plans.dates;
        const sortedArray = array.sort((a, b) => {
            return new Date(a[0]) - new Date(b[0]);
        });

        const object = {};
        const weeks = {};
        for (let item of sortedArray) {
            const [date, namedMonth, week, namedDay] = item;
            const [year, month, day] = date.split('-');
            const bucket = {
                day: day,
                namedDay: namedDay,
                date: date,
                week: week
            };
            const key = `${year}-${week}`;
            if (!weeks.hasOwnProperty(key)) {
                weeks[key] = [];
            }
            weeks[key].push(bucket);
            if (object.hasOwnProperty(`${year}-${month}`)) {
                object[`${year}-${month}`].daysCount++;
                continue
            }
            object[`${year}-${month}`] = {
                month: namedMonth,
                daysCount: 1
            }
        }

        this.setState({
            sorted: object,
            weeks: weeks
        })
    };

    changeStartDate = date => {
        this.setState({
            start_date: date
        })
    };

    changeEndDate = date => {
        this.setState({
            end_date: date
        })
    };

    getDatedList = async () => {
        const dates = {
            start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.end_date).format('YYYY-MM-DD')
        };

        history.push(`/all_plans/?start_date=${dates.start_date}&end_date=${dates.end_date}`);

        this.setState({
            isLoad: false
        });
        await this.props.getPlans(dates);
        this.sortArray();
        this.setState({
            isLoad: true
        });
    };

    render() {
        if(!this.props.user) {
            return <Redirect to='/login'/>
        }
        const months = Object.keys(this.state.sorted);
        return (
            <Fragment>
                <div className="container" id="datepicker_for_plans_container">
                    <Row>
                        <Col>
                            <Label>Start Date</Label>
                            <DatePicker
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                                selected={this.state.start_date}
                                onChange={(date) => this.changeStartDate(date)}
                            />
                        </Col>
                        <Col>
                            <Label>End Date</Label>
                            <DatePicker
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                                selected={this.state.end_date}
                                onChange={(date) => this.changeEndDate(date)}
                            />
                        </Col>
                        <Col>
                            <Button color='primary' onClick={this.getDatedList}>Show Plans</Button>
                        </Col>
                    </Row>
                </div>

                <div className='row'>
                    <div className='col'>
                        {this.state.isLoad && months.length && this.props.plans ? <TableComponent
                            sorted={this.state.sorted}
                            weeks={this.state.weeks}
                            plans={this.props.plans}
                            months={months}
                        /> : null}
                        {this.props.plans && this.props.plans.dates.length < 1 ?
                            <Alert color='warning'>Тo travel plans. Please select date</Alert> : null}
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    plans: state.plan.plansList,
    user: state.usr.user
});

const mapDispatchToProps = dispatch => ({
    getPlans: (dates) => dispatch(getPlans(dates))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansList);
