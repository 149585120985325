import React, {Fragment} from 'react';
import {
    Modal,
    ModalHeader,
    FormGroup,
    Button,
    Label,
    Input,
    ModalBody,
    ModalFooter,
    Col,
    Row,
} from 'reactstrap'
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css"
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";
import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import moment from "moment";

const editorConfig = customConst.editorConfig;

const HotelForm = props => {
    let isDisabled = false;

    if (props.hotelOrder.is_blocked) {
        isDisabled = true;
    }

    let isVisible = true;
    const user = props.user;
    if(user.is_admin === false) {
        if(user.user !== props.plan.owner) {
            isVisible = false
        } else {
            isVisible = true
        }
    }

    let datesForTheMail = "<p>Заезд: " + moment(new Date(props.hotelOrder.start_date)).format('DD.MM') + "</p><p>Выезд: " + moment(new Date(props.hotelOrder.end_date)).format('DD.MM') + "</p>";

    return (
        <Fragment>
            <Modal
                id='hotel_modal'
                isOpen={props.isOpen}
                toggle={() => props.closeModalHandler('hotel')}
                size="lg"
            >
                <ModalHeader>Hotel Order</ModalHeader>

                <ModalBody>
                    <Row>
                        <Col>
                            <Label for='hotel_start'>Start date</Label>
                            <FormGroup>
                                <DatePicker
                                    disabled={isDisabled}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy HH:mm"
                                    selected={new Date(props.hotelOrder.start_date)}
                                    onChange={date => props.setStartDateHandler(date)}
                                    minDate={new Date(props.planDetails.first_day_date)}
                                    maxDate={new Date(props.hotelOrder.end_date)}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <Label for='hotel_end'>End date</Label>
                            <FormGroup>
                                <DatePicker
                                    disabled={isDisabled}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy HH:mm"
                                    selected={new Date(props.hotelOrder.end_date)}
                                    onChange={date => props.setEndDateHandler(date)}
                                    minDate={new Date(props.planDetails.first_day_date)}
                                    maxDate={new Date(props.hotelOrder.end_date)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='hotel_mail'>Mail</Label>
                                <CKEditor
                                    id='hotel_mail'
                                    readOnly={isDisabled}
                                    type={editorConfig.type}
                                    data={props.hotelOrder.mail ? props.hotelOrder.mail : datesForTheMail}
                                    config={editorConfig}
                                    onChange={props.ckEditorChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='hotel_sms'>Sms</Label>
                                <Input
                                    disabled={isDisabled}
                                    type='textarea'
                                    id='hotel_sms'
                                    name='sms'
                                    value={props.hotelOrder.sms}
                                    onChange={props.inputChangeHandler}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='hotel_note'>Note</Label>
                                <Input
                                    disabled={isDisabled}
                                    type='textarea'
                                    id='hotel_note'
                                    name='note'
                                    value={props.hotelOrder.note}
                                    onChange={props.inputChangeHandler}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Label for='hotel_status'>Status</Label>
                            <Input type="select"
                                   disabled={isDisabled}
                                   name="status"
                                   id="hotel_status"
                                   onChange={props.inputChangeHandler}
                                   value={props.hotelOrder.status}
                            >
                                <option value='none'>None</option>
                                <option defaultValue value='not_confirmed'>Not confirmed</option>
                                <option value='waiting_list'>Waiting list</option>
                                <option value='confirmed'>Confirmed</option>
                                <option value='canceled'>Canceled</option>
                            </Input>
                        </Col>
                    </Row>

                    <div className="last_notification_wrapper">
                        <p className="last_sms_wrapper">
                            {!props.hotelOrder.last_sms ? ' ' : [
                                <b key="1-1">Last SMS: </b>,
                                <span key="1-2">{props.hotelOrder.last_sms}</span>,
                                <TooltipComponent key="1-3" description={props.hotelOrder.last_sms_text}
                                                  id={'tooltip_last_sms'}/>
                            ]}
                        </p>

                        <p className="last_email_wrapper">
                            {!props.hotelOrder.last_email ? ' ' : [
                                <b key="2-1">Last Email: </b>,
                                <span key="2-2">{props.hotelOrder.last_email}</span>,
                                <TooltipComponent key="2-3" description={props.hotelOrder.last_email_mail}
                                                  id={'tooltip_last_email'}/>
                            ]}
                        </p>
                    </div>

                </ModalBody>

                <ModalFooter>
                    {isVisible ? <Fragment>
                        {props.hotelOrderId ?
                            <Button
                                color="primary"
                                name='update'
                                onClick={props.updateOrderHandler}
                                disabled={isDisabled}
                            >
                                Update
                            </Button>
                            : <Button
                                color="primary"
                                name='save'
                                onClick={props.createOrderHandler}
                                disabled={isDisabled}
                            >
                                Save
                            </Button>}

                        {props.hotelOrderId ? <Button
                            color='success'
                            name='update_send'
                            onClick={props.updateOrderHandler}
                            disabled={isDisabled}
                        >
                            Update & Send
                        </Button> : <Button
                            color='success'
                            name='save_send'
                            onClick={props.createOrderHandler}
                            disabled={isDisabled}
                        >
                            Save & Send
                        </Button>}
                    </Fragment> : null}

                    <Button color="danger" onClick={() => props.closeModalHandler('hotel')}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default HotelForm;
