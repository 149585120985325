import React, {Fragment, useState} from 'react';
import {FormGroup, Row, Col, Label, Input, Alert} from 'reactstrap';
import CKEditor from 'ckeditor4-react';
import customConst from "../../../global";
import {faPaperclip, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePickerComponent from "../../UI/Datepicker/Datepicker";
import DatePicker from "react-datepicker/es";

const editorConfig = customConst.editorConfig;

const myImageRef = React.createRef();
const myExcelRef = React.createRef();


const EditFormTour = props => {

    const clearImage = () => {
        myImageRef.current.value = null;

        props.clearImage()
    };
    const clearExcel = () => {
        myExcelRef.current.value = null;
        props.clearExcelInTour()
    };

    const [startDate, setStartDate] = useState(new Date());

    return (
        props.tour && props.tourMetas ?
            <Fragment>
                <Row>
                    <Col>
                        <FormGroup>

                            <Label>Set a new tour start date</Label>
                            {props.tour.plan ? <Alert color="warning">
                                You can set a new tour start date. All date days will be changed automatically!
                            </Alert> : null}
                            <DatePicker
                                dateFormat="E MMMM d, yyyy"
                                onChange={(date) => props.changeHandler({target: { name: "new_start_date", value: date }})}
                                className="form-control"
                                selected={props.tour.new_start_date}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='category'>Categories *</Label>
                            <Input
                                disabled={props.disabled}
                                required
                                type="select"
                                name="category"
                                id="category"
                                onChange={props.changeHandler}
                                value={props.tour.category}
                            >
                                <option value="">Choose category</option>
                                {props.tourMetas.categories.map(category => {
                                    return (
                                        <option
                                            key={category.id}
                                            value={category.id}
                                        >{category.title}</option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label>Duration *</Label>
                            <Input
                                disabled={props.disabled}
                                type='select'
                                name='duration'
                                onChange={props.changeHandler}
                                value={props.tour.duration}
                            >
                                <option>Choose duration</option>
                                {props.tourMetas.durations.map(duration => {
                                    return (
                                        <option
                                            key={duration.id}
                                            value={duration.id}
                                        >{duration.title}</option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label>Tour title *</Label>
                    <Input
                        disabled={props.disabled}
                        required
                        type='text'
                        name='title'
                        onChange={props.changeHandler}
                        value={props.tour.title}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for='tour_description'>Description</Label>
                    <CKEditor
                        readOnly={props.disabled}
                        id='tour_description'
                        type={editorConfig.type}
                        data={!props.tour.description ? '' : props.tour.description}
                        config={editorConfig}
                        onChange={props.ckEditor}
                        name='description'
                    />
                </FormGroup>

                <Row>
                    <Col>
                        <Label for='image'>Image</Label>
                        <FormGroup>
                            <div className="tour_attachment_wrapper">
                                {props.tour.image && (props.tour.image !== '' && props.tour.image.length > 0) ?
                                    <a className="attach_link" href={props.tour.image} target='_blank'><FontAwesomeIcon
                                        className="icon" icon={faPaperclip}/> View</a>
                                    : null
                                }

                                <Input type='file'
                                       id='image'
                                       disabled={props.disabled}
                                       name='image'
                                       onChange={props.imageTourChange}
                                       innerRef={myImageRef}
                                />
                                {props.tour.image && (props.tour.image !== '' && props.tour.image.length > 0) ?
                                    !props.disabled ?
                                        <span className='remove-file' onClick={clearImage}><FontAwesomeIcon
                                            icon={faTimesCircle}/></span> : null
                                    : null
                                }
                            </div>
                        </FormGroup>
                    </Col>

                </Row>

                <Row>
                    <Col className="d-none">
                        <FormGroup>
                            <Label>Price for *</Label>
                            <Input onChange={props.changeHandler} type='select' name='price_for'
                                   disabled={props.disabled}
                                   value={props.tour.price_for}>
                                <option>Choose type</option>
                                <option value='1'>For person</option>
                                <option value='2'>For group</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label for='excel'>Excel</Label>
                        <FormGroup>
                            <div className="tour_attachment_wrapper">
                                {props.tour.excel && (props.tour.excel !== '' && props.tour.excel.length > 0) ?
                                    <a className="attach_link" href={props.tour.excel} target='_blank'><FontAwesomeIcon
                                        className="icon" icon={faPaperclip}/> View</a>
                                    : null
                                }
                                <Input type='file'
                                       disabled={props.disabled}
                                       name='excel'
                                       id='excel'
                                       onChange={props.excelFileTour}
                                       innerRef={myExcelRef}
                                />
                                {props.tour.excel && (props.tour.excel !== '' && props.tour.excel.length > 0) ?
                                    !props.disabled ?
                                        <span className='remove-file' onClick={clearExcel}><FontAwesomeIcon
                                            icon={faTimesCircle}/></span> : null
                                    : null
                                }
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </Fragment> :
            null
    );
};

export default EditFormTour;
