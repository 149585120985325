import React, {Fragment} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ProvidersTabs from "../../TravelPlanComponents/ProvidersTabs/ProvidersTabs";

const ServiceProvidersModal = (props) => {
    return (
        <Fragment>
            <Modal id='service_provider_modal'
                   isOpen={props.modal}
                   toggle={() => props.toggle()}
                   size="lg">
                <ModalHeader>Add service provider</ModalHeader>
                <ModalBody>

                    {props.providers && <ProvidersTabs
                        modalHandler={props.modalHandler}
                        providers={props.providers}
                    /> }


                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => props.toggle()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default ServiceProvidersModal;
