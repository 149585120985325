import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getTouristsTours} from "../../store/actions/touristsActions";

import {Button, Row, Col} from 'reactstrap';
import {NavLink} from "react-router-dom";
import Preloader from "../../components/UI/Preloader/Preloader";
import TooltipComponent from "../../components/UI/TooltipComponent/TooltipComponent";

class TouristInfo extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getTouristsTours(id);
    }

    renderData = () => {
        const info = this.props.touristTours && !this.props.isLoading ?
            <div>
                <h1 className="page_title">{this.props.touristTours.tourist.full_name}'s tours</h1>

                <ul className="list-unstyled custom_list">
                    {this.props.touristTours.tours.map((tour, index) => {
                        return (
                            <li id={"tour_" + index} key={index} className="tour_li">
                                <NavLink to={`/tour_edit/${tour.id}`}
                                         className={(tour.owner !== this.props.touristTours.user ? "disallow_edit " : "") + (tour.plan_canceled === "True" ? "canceled" : "")}>
                                    <span className="tour_id ">{"T" + tour.id} </span>
                                    <span className="tour_date">{tour.start_date !== null ? "("+tour.start_date+")": ""}</span> {tour.title}
                                </NavLink>
                                <TooltipComponent
                                    description={tour.owner_email}
                                    id={`tooltip_tour_${tour.id}`}
                                />
                            </li>
                        )
                    })}
                </ul>
            </div> :
            <Preloader/>;
        return info
    };

    render() {
        return (
            <Fragment>
                {this.renderData()}
                <div className="line_helper"></div>
                <Row>
                    <Col>
                        <Button color='success'>
                            <NavLink style={{color: '#fff', textDecoration: 'none'}}
                                     to={`/editTourist/${this.props.match.params.id}`}>
                                Update tourist
                            </NavLink>
                        </Button>
                    </Col>
                    <Col>
                        <Button color='primary'>
                            <NavLink style={{color: '#fff', textDecoration: 'none'}} to={`/create-tour/${this.props.match.params.id}`}>
                                Add new tour
                            </NavLink>
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    touristTours: state.tr.touristTours,
    isLoading: state.tr.isLoading
});

const mapDispatchToProps = {
    getTouristsTours
};

export default connect(mapStateToProps, mapDispatchToProps)(TouristInfo);
