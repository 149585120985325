import React, {Fragment} from 'react';
import {Container} from 'reactstrap';
import {connect} from 'react-redux';

import Header from '../UI/Header/Header';
import Footer from '../UI/Footer/Footer'
import {logoutUser} from "../../store/actions/userActions";
import {NotificationContainer} from 'react-notifications';

const Layout = props => {
    return (
        <Fragment>
            <NotificationContainer/>
            <Header logout={props.logoutUser} user={props.user}/>
            <Container>
                <div>
                    <main className="container" id="main_container">
                        {props.children}
                    </main>
                </div>
            </Container>
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => ({
    user: state.usr.user
});

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
